export enum PointStatus {
  None = "Nieznany",
  Leaflet = "Ulotka",
  PhoneTalk = "Rozmowa telefoniczna",
  FaceTalk = "Rozmowa z klientem",
  Measure = "Pomiar",
  Offer = "Oferta wyslana",
  Negotiation = "Negocjacje",
  Accept = "Podpisano",
  NotAccept = "Nie podpisano",
}
