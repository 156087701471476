import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY } from "rxjs";
import { map, mergeMap, catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { CalendarEventsEndpoint } from "@src/app/endPoints/CalendarEndpoints";

@Injectable()
export class SaveCalendarEventEffect {
  static effectName = "[CalendarEvent] create new";
  loadMovies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveCalendarEventEffect.effectName),
      mergeMap(() =>
        this.calendarEventRest.getAll().pipe(
          map((movies) => ({
            type: "[CalendarEvent] Loaded Success",
            payload: movies,
          })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private calendarEventRest: CalendarEventRest
  ) {}
}

@Injectable()
export class CalendarEventRest {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get(CalendarEventsEndpoint.baseUrl);
  }
}
