import {
  ISynchronizable,
  SyncStateEnum,
} from "@src/app/points/dataAccess/sync/ISynchronizable";

export class Offer implements ISynchronizable {
  id: number;
  investmentId: number;
  status = OfferStatusEnum.new;
  grossValue: number;
  vatTax: number;
  netValue: number;
  creationDate = new Date().toISOString();
  expireDate = new Date().toISOString();
  note = "";
  name = "";
  isEdited = false;
  isDeleted: boolean;
  feId: number;
  syncState: SyncStateEnum = SyncStateEnum.userCreated;
  files: IFile[];
  static getExpirationInDays(offer: Offer) {
    const currentDate = new Date();
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    if (!offer?.expireDate) return undefined;

    return Math.floor(
      (new Date(offer.expireDate).getTime() - currentDate.getTime()) /
        _MS_PER_DAY
    );
  }
}

export interface IFile {
  fileName: string;
  url: string;
}

export enum OfferStatusEnum {
  new = "Nowa",
  accepted = "Zaakceptowana",
  refused = "Odrzucona",
  outDated = "Przeterminowana",
}
