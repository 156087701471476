import { ServiceLocator } from "./ServiceLocator";
import { ActivatedRoute, Router } from "@angular/router";

export const addQueryParam = async (params: { [key: string]: any }) => {
  const router = ServiceLocator.injector.get(Router);
  const route = ServiceLocator.injector.get(ActivatedRoute);
  await router.navigate([], {
    relativeTo: route,
    queryParams: params,
    queryParamsHandling: "merge",
    // skipLocationChange: true,
  });
};


export const removeQueryParam = async (paramKey: string) => {
  const router = ServiceLocator.injector.get(Router);
  const route = ServiceLocator.injector.get(ActivatedRoute);
  
  const params = { [paramKey]: null };
  
  await router.navigate([], {
    relativeTo: route,
    queryParams: params,
    queryParamsHandling: 'merge'
  });
};
