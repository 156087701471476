import { Component, OnInit } from "@angular/core";
import { SyncService } from "@src/app/points/dataAccess/sync/sync.service";
import Chart, { ChartDataset } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { MapInvestment } from "@src/app/points/models/investment";
import _ from "lodash";
import { InvestmentRepository } from "@src/app/points/dataAccess/investmentRepository";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  investments: MapInvestment[];
  constructor(private syncService: SyncService) {}

  async ngOnInit() {
    await this.syncService.sync();
    this.investments = await InvestmentRepository.getAll();
  }

  buildBuildingStatusesChart() {
    const myChart = new Chart("building-statuses-chart", {
      type: "pie",
      data: {
        labels: [
          "Stworzony przez użytkownika",
          "Pusta działka",
          "Wytyczono budynek",
          "Wykopy",

          "Fundamenty",
          "Prace murarskie",
          "Zalany strop",
          "Mury wzniesione",

          "Prace dekarskie",
          "Surowy otwarty",
          "Surowy zamknięty",
          "Zamieszkały",
        ],
        datasets: [
          {
            data: [6, 21, 15, 3, 11, 33, 20, 30, 10, 8, 5, 27],
            backgroundColor: [
              "#676766",
              "#ffcd33",
              "#f26522",
              "#4e9fa5",

              "#add5d7",
              "#f9aa7b",
              "#ffe8af",
              "#a5a8aa",

              "#ed5d5a",
              "#ecc070",
              "#88ae87",
              "#d8e2ec",
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          datalabels: {
            display: false,
          },
          title: {
            display: true,
            text: "Etapy prac budow. wśród odwiedzonych punktów",
            align: "start",
            font: {
              size: 15,
            },
          },
          legend: {
            display: true,
            position: "right",
            labels: {
              boxWidth: 20,
              padding: 5,
              font: {
                size: 12,
              },
            },
            maxWidth: 150,
          },
        },
      },
    });
  }

  buildDoughnutChartWithInvestmentsData() {
    Chart.register(ChartDataLabels);
    const myChart = new Chart("doughnut-chart", {
      type: "doughnut",
      data: {
        labels: [
          "Nieodwiedzone",
          "Ulotka",
          "Rozmowa z klientem",
          "Negocjacje",
          "Oferta odrzucona",
          "Podpisano",
        ],
        datasets: [
          {
            data: [20, 30, 10, 8, 5, 27],
            backgroundColor: [
            '#c65245ff',
              "#FF8C00",
              "#FFD700",
              "#006400",
              "#676766",
              "#228B22",
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          datalabels: {
            display: true,
            align: "center",
            color: "#FFF",
            font: {
              size: 15,
            },
          },
          title: {
            display: true,
            text: "Wszystkie inwestycje",
            align: "start",
            font: {
              size: 15,
            },
          },
          legend: {
            display: true,
            position: "right",
            labels: {
              boxWidth: 20,
              padding: 20,
              font: {
                size: 10,
              },
            },
            maxWidth: 150,
          },
        },
      },
    });
  }

  getVisitedInvestmentsCount() {
    const count = _.countBy(this.investments, function (investment) {
      return investment.isVisited;
    });

    return count.true;
  }
  getAllInvestmentsCount() {
    return this.investments?.length;
  }
}
