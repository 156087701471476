export class Log {
  static Error(...data: any[]) {
    console.error(...data);
  }

  static info(txt: string) {
    console.log(txt);
  }

  static debug(...data: any[]) {
    // console.log(...data);
  }
}
