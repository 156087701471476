import State from "@src/app/State/appState";
import { NoteRepository } from "@src/app/points/dataAccess/noteRepository";
import { Note } from "@src/app/points/windows/info-tabs/notes/models/note";
import { SyncInvestmentServiceInstance } from "@src/app/points/dataAccess/sync/sync-investment.service";
import { syncStateService } from "@src/app/State/lastSyncState";
import { SyncGenericWithStream } from "@src/app/points/dataAccess/sync/GenericSync";
import {NoteEndpoint} from "@src/app/endPoints/NoteEndpoints";
import {DELETE} from "@src/utils/HTTP";
import {SyncStateEnum} from "@src/app/points/dataAccess/sync/ISynchronizable";

class NoteService {
  async sync(isForce = false) {
    const lastSyncDate = syncStateService.state.value.note?.lastSyncDate;
    await SyncGenericWithStream({
      type: Note,
      query: { lastSyncDate: new Date(lastSyncDate) },
      repository: NoteRepository,
      endpoint: NoteEndpoint,
      forceSync: isForce
    });
    syncStateService.setNoteLastSyncState();
    return true;
  }

  async deleteNote(feId: number) {
    NoteRepository.remove(feId);
    const query = "ids=" + feId;
    await DELETE(`${NoteEndpoint.deleteRange}?${query}`);
    await this.loadNotes();
  }

  async addNewNote(note: Note) {
    note.investmentId = State.mapState.value.selectedInvestment.value.id;
    note.syncState = SyncStateEnum.userCreated;
    NoteRepository.add(note);
    await this.sync(true);
    await this.loadNotes();
  }

  async loadNotes() {
    const selectedInvestment = State.mapState.value.selectedInvestment.value.id;
    const notes = await NoteRepository.search(n => n.investmentId === selectedInvestment);
    State.data.notes.items.next(notes);
  }

  async addNewNoteFunc(note: Note) {
    await SyncInvestmentServiceInstance.syncSelectedIfNoId();
    const investmentId = State.mapState.value.selectedInvestment.value.id;
    const noteNote = { ...note } as Note;
    noteNote.investmentId = investmentId;
    await NoteRepository.add(noteNote);
    return noteNote;
  }
}

export const NoteServiceInstance = new NoteService();
