import { Component } from "@angular/core";
import { Router } from "@angular/router";
import * as NoSleep from "nosleep.js";

import { AuthService } from "../../services/auth.service";
import { ToastrService } from "ngx-toastr";
import { OnDestroyBase } from "../../../common/OnDestroyBase";
import {storageService} from "@src/app/services/storageService";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["../../../../assets/styles/shared/auth.scss"],
})
export class LoginComponent extends OnDestroyBase {
  email = "";
  password = "";
  errorMessage: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastrService: ToastrService
  ) {
    super();
  }

  async login() {
    this.enableNoSleep();
    if (!this.email || !this.password) {
      this.errorMessage = "You need to provide valid email and password.";
      return;
    }

    if (localStorage.getItem("user") && localStorage.getItem("user") !== this.email) {
      await storageService.cleanup();
    }

    this.subscriptions.push(
      this.authService.login(this.email, this.password).subscribe(
        (data) => {
          localStorage.setItem("authToken", data.token);
          localStorage.setItem("user", this.email);
          this.router.navigate(["/"]);
        },
        (error) => {
          this.toastrService.error("Błędny login lub hasło", "");
        }
      )
    );
  }
  private enableNoSleep(): any {
    const noSleep = new NoSleep();
    noSleep.enable();
  }

  toRegister() {
    this.router.navigate(["/register"]);
  }

  toResetPassword() {
    this.router.navigate(["/forgotPassword"]);
  }
}
