import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { ToastrService } from "ngx-toastr";
import { OnDestroyBase } from "@src/app/common/OnDestroyBase";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["../../../../assets/styles/shared/auth.scss"],
})
export class ForgotPasswordComponent extends OnDestroyBase {
  email = "";
  errorMessage: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastrService: ToastrService
  ) {
    super();
  }

  onSubmit() {
    if (!this.email) {
      this.errorMessage = "Musisz podać prawidłowy adres e-mail.";
      return;
    }
    this.subscriptions.push(
      this.authService.forgotPassword(this.email).subscribe(
        (data) => {
          this.toastrService.success(
            "Link do resetowania hasła został wysłany na Twój adres e-mail",
            ""
          );
          this.router.navigate(["/login"]);
        },
        (error) => {
          this.errorMessage =
            "Błąd podczas wysyłania linku do resetowania hasła.";
          this.toastrService.error(this.errorMessage, "");
        }
      )
    );
  }

  toLogin() {
    this.router.navigate(["/login"]);
  }
}
