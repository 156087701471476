import {
  apiUrl,
  createSyncEndpoints,
  ISynchronizableUrl,
} from "@src/app/endPoints/endPoints";

interface ICalendarEventsEndpoint extends ISynchronizableUrl {
  baseUrl: string;
}

const base = `${apiUrl()}/Note`;
const syncEndpoints = createSyncEndpoints(base);

export const NoteEndpoint: ICalendarEventsEndpoint = {
  baseUrl: base,
  ...syncEndpoints,
};
