import { RepositoryBase } from "./repositoryBase";
import { Offer } from "@src/app/points/windows/info-tabs/offer/models/offer";

class OfferRepository extends RepositoryBase<Offer> {
  constructor() {
    super(Offer, "Offer");
  }
}

export const OfferRepositoryInstance = new OfferRepository();
