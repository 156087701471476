import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-new-point",
  templateUrl: "./new-point.component.html",
  styleUrls: ["./new-point.component.css"],
})
export class NewPointComponent implements OnInit {
  constructor(store: Store) {}

  ngOnInit(): void {}
}
