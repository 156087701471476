import * as _ from "lodash";
import {
  ISynchronizable,
  SyncStateEnum,
} from "@src/app/points/dataAccess/sync/ISynchronizable";
import { POST, PUT } from "@src/utils/HTTP";

export interface ISyncPageQuery {
  lastSyncDate: Date;
}

export interface IPageResult<T> {
  items: T[];
  hasNext: boolean;
}

export interface ISyncPageRequest<TQuery extends ISyncPageQuery> {
  query: TQuery;
  page: number;
  itemsPerPage: number;
}

export class PaginationService<T extends ISynchronizable> {
  constructor(private type: new () => T) {}
  async fetchNewItems<TQuery extends ISyncPageQuery>(
    request: ISyncPageRequest<TQuery>,
    url: string,
    syncStatus: SyncStateEnum = SyncStateEnum.inSync
  ): Promise<T[]> {
    const newItems: Array<T> = [];
    let hasNextPage = true;
    do {
      const result = await POST<IPageResult<T>>(url, request);
      result.items.forEach((x) => (x.syncState = syncStatus));
      newItems.push(...result.items);
      request.page++;
      hasNextPage = result.hasNext;
    } while (hasNextPage);
    return newItems.map((x) => Object.assign(new this.type(), x)) as T[];
  }

  async updateItemsInServer(url: string, itemsToSync: T[]): Promise<T[]> {
    const currentDate = new Date();
    const chunks = _.chunk(itemsToSync, 500);
    const result = [];
    for (const x of chunks) {
      const items = await PUT<T[]>(url, x);
      result.push(...items);
      x.forEach((i) => {
        i.lastUpdate = currentDate.toDateOnlyIsoString();
        i.syncState = SyncStateEnum.inSync;
      });
    }
    return result;
  }

  async createItemsInServer(url: string, itemsToCreate: T[]): Promise<T[]> {
    const currentDate = new Date();
    const chunks = _.chunk(itemsToCreate, 500);
    const result: T[] = [];
    for (const x of chunks) {
      const items = await POST<T[]>(url, x);
      items.forEach((i) => {
        i.lastUpdate = currentDate.toDateOnlyIsoString();
        i.syncState = SyncStateEnum.inSync;
      });
      result.push(...items);
    }
    return result;
  }
}
