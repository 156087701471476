import { Component } from "@angular/core";
import { Router } from "@angular/router";
import * as NoSleep from "nosleep.js";

import { AuthService } from "../../services/auth.service";
import { ToastrService } from "ngx-toastr";
import { OnDestroyBase } from "@src/app/common/OnDestroyBase";
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["../../../../assets/styles/shared/auth.scss"],
})
export class RegisterComponent extends OnDestroyBase {
  email = "";
  password = "";
  fullname = "";
  username = "";
  errorMessage: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastrService: ToastrService
  ) {
    super();
  }

  onSubmit() {
    if (!this.email || !this.password) {
      this.errorMessage = "You need to provide valid email and password.";
      return;
    }
    this.subscriptions.push(
      this.authService
        .register(this.email, this.password, this.fullname, this.username)
        .subscribe(
          (data) => {
            if (data.succeeded) {
              this.toLogin();
            } else {
              this.toastrService.error("Login zajęty", "Błąd");
            }
          },
          (error) => {
            console.log("error", error);
          }
        )
    );
  }
  private enableNoSleep(): any {
    var noSleep = new NoSleep();
    noSleep.enable();
  }

  toLogin() {
    this.router.navigate(["/login"]);
  }
}
