import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import {
  CalendarEvent,
  CalendarEventStatus,
} from "@src/app/points/models/event";
import { CounterPartyApiService } from "@src/app/navibud-calendar/scheduler/add-event/counter-party-api.service";
import State from "@src/app/State/appState";

export interface IAddEventComponentData {
  event: CalendarEvent;
  isNew: boolean;
}

@Component({
  selector: "app-add-event",
  templateUrl: "./add-event.component.html",
  styleUrls: ["./add-event.component.css"],
})
export class AddEventComponent implements OnInit {
  isNew = true;
  event = new CalendarEvent();
  statuses = Object.values(CalendarEventStatus).filter(String);
  isInfoWindowOpen = false;
  public counterparties: string[];
  constructor(
    private ref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: IAddEventComponentData,
    public dialogRef: MatDialogRef<AddEventComponent>,
    private counterPartyApiService: CounterPartyApiService
  ) {
    if (this.data) {
      this.event = this.data?.event || new CalendarEvent();
      this.isNew = this.data?.isNew;
    }
  }

  ngOnInit() {
    this.counterPartyApiService
      .getAll()
      .subscribe((x) => (this.counterparties = x));
    this.isInfoWindowOpen = State.mapState.value.infoWindowStatus.value > 0;
  }

  closeDialog() {
    this.dialogRef.close(this.event);
  }
}
