<div class="home-container">
  <!-- <div class="statistic-charts">
        <div class="dashboard-item">
            <app-line-chart></app-line-chart>
        </div>
        <div class="dashboard-item dashboard-item-wide investments-counts">
            <div class="all-investments">
                <span class="count">{{getAllInvestmentsCount()}}</span>
                <p>Wszystkie inwestycje</p>
            </div>
            <div class="line-divider"></div>
            <div class="visited-investments">
                <span class="count">{{getVisitedInvestmentsCount()}}</span>
                <p>Odwiedzone inwestycje</p>
            </div>
            <div class="line-divider"></div>
            <div class="planned-visits">
                <span class="count">24</span>
                <p>Zaplanowane odwiedziny</p>
            </div>
        </div>
        <div class="dashboard-item">
            <app-doughnut-chart></app-doughnut-chart>
        </div>
        <div class="dashboard-item">
            <app-building-statuses-chart></app-building-statuses-chart>
        </div>

    </div> -->
  <app-scheduler class="modal-calendar"></app-scheduler>
</div>
