declare global {
  interface Date {
    isSameDay(this: Date, date: Date): boolean;
    toDateOnlyIsoString(this: Date): string;
    toNoMillisecondIsoString(this: Date): string;
  }

  interface String {
    toDateOnlyString(this: string): string;
  }
}

Date.prototype.isSameDay = function (this: Date, date2: Date) {
  return (
    this.getFullYear() === date2.getFullYear() &&
    this.getMonth() === date2.getMonth() &&
    this.getDate() === date2.getDate()
  );
};

Date.prototype.toDateOnlyIsoString = function (this: Date) {
  return this.toISOString().substring(0, 10);
};

Date.prototype.toNoMillisecondIsoString = function (this: Date) {
  return `${this.toISOString().split('.')[0]}Z`;
};

String.prototype.toDateOnlyString = function (this: String) {
  return this.substring(0, 10);
};

export {};
