import { Injectable } from "@angular/core";
@Injectable()
export class LayoutService {
  sidenavOpened = false;

  get isSidenavOpened(): boolean {
    return this.sidenavOpened;
  }

  openSidenav(): void {
    this.sidenavOpened = true;
  }

  closeSidenav(): void {
    this.sidenavOpened = false;
  }

  toggleSidenav(): void {
    this.sidenavOpened = !this.sidenavOpened;
  }
}
