<mat-toolbar class="main-toolbar">
    <mat-toolbar-row>
        <div class="main-toolbar-left">
            <a class="logo" href="/">
                <div class="">
                    <img class="logo-image" src="/assets/logo.png" />
                </div>
            </a>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
<div class="page">
    <div class="right-half-screen-cover">
        <div class="form-div">
            <p>Confirm email response: {{ message }}</p>
            <a *ngIf="success" href="/login" class="btn btn-primary">Zaloguj się</a>
        </div>
    </div>
</div>
