import { SpaceTime } from "../models/spaceTime";
import { RepositoryBase } from "./repositoryBase";

class GeolocationRepositoryClass extends RepositoryBase<SpaceTime> {
  constructor() {
    super(SpaceTime, "GeoLocation");
  }
}

export const GeolocationRepository = new GeolocationRepositoryClass();
