<div class="line-chart-container">
  <div class="chart-info-button">
    <button mat-icon-button aria-label="Szczegóły">
      <mat-icon>info</mat-icon>
    </button>
  </div>

  <canvas id="line-chart"></canvas>
  <div class="line-chart-buttons">
    <button mat-stroked-button (click)="lastDaysClick(7)">7 dni</button>
    <button mat-stroked-button (click)="lastDaysClick(14)">14 dni</button>
    <button mat-stroked-button (click)="lastDaysClick(30)">
      ostatni mies.
    </button>
    <button mat-stroked-button (click)="lastMonthsClick(6)">6 mies.</button>
    <button mat-stroked-button (click)="lastMonthsClick(12)">rok</button>
  </div>
</div>
