import { MapInvestment } from "./investment";
import {
  ISynchronizable,
  SyncStateEnum,
} from "@src/app/points/dataAccess/sync/ISynchronizable";

export class Route implements ISynchronizable {
  id: number;
  points?: Array<MapInvestment>;
  name: string;
  syncState: SyncStateEnum;
  feId: number;
  isDeleted: boolean;

  // constructor(investments: Array<MapInvestment>, name: string) {
  //     this.points = investments;
  //     this.name = name;
  // }
}
