<div class="frame">
  <h2>Ustawienia konta</h2>
  <div class="user-data-container">
    <div class="user-data-section">
      <div class="user-data-section-icon">
        <mat-icon>perm_identity</mat-icon>
      </div>
      <div class="user-personal-data details">
        <div>
          <h4>Nazwa konta</h4>
          <span>{{ login }}</span>
        </div>
        <div>
          <h4>Typ konta</h4>
          <span>{{ role }}</span>
        </div>
        <div>
          <h4>Telefon</h4>
          <span>+48 666 555 666</span>
        </div>
        <button mat-button-base disabled>
          <mat-icon>edit</mat-icon>
          Edytuj
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div class="user-data-section">
      <div class="user-data-section-icon">
        <mat-icon>mail_outline</mat-icon>
      </div>
      <div class="user-data-email details">
        <div>
          <h4>Adres e-mail</h4>
          <span>-</span>
        </div>
        <button mat-button-base disabled>
          <mat-icon>edit</mat-icon>
          Edytuj
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="user-data-section">
      <div class="user-data-section-icon">
        <mat-icon>password</mat-icon>
      </div>
      <div class="user-data-pass details">
        <div class="">
          <h4>Hasło</h4>
          <span>*********</span>
        </div>
        <button mat-button-base (click)="openPasswordDialog()">
          <mat-icon>edit</mat-icon>
          Edytuj
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
</div>
