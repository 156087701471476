import { FilterStatusOption } from "./filterStatusOption";

export const constructionStatuses: FilterStatusOption[] = [
    { displayName: "Nieznany", backendName: "nieznany" },
    { displayName: "Stworzony przez użytkownika", backendName: "stworzony_przez_uzytkownika" },
    { displayName: "Pusta działka", backendName: "pusta_dzialka" },
    { displayName: "Wytyczono budynek", backendName: "wytyczono_budynek" },
    { displayName: "Wykopy", backendName: "wykopy" },
    { displayName: "Fundamenty", backendName: "fundamenty" },
    { displayName: "Prace murarskie", backendName: "prace_murarskie" },
    { displayName: "Zalany strop", backendName: "zalany_strop" },
    { displayName: "Mury wzniesione", backendName: "mury_wzniesione" },
    { displayName: "Prace dekarskie", backendName: "prace_dekarskie" },
    { displayName: "Surowy otwarty", backendName: "surowy_otwarty" },
    { displayName: "Surowy zamknięty", backendName: "surowy_zamkniety" },
    { displayName: "Zamieszkały", backendName: "zamieszkaly" },
    { displayName: "Niezamieszkany", backendName: "niezamieszkany" },
  ];