import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

// TODO: Add Angular decorator.
@Component({
  template: "",
})
export abstract class OnDestroyBase implements OnDestroy {
  subscriptions: Array<Subscription> = [];

  ngOnDestroy() {
    if (!this.subscriptions) return;
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}
