import {
  ISynchronizable,
  SyncStateEnum,
} from "@src/app/points/dataAccess/sync/ISynchronizable";

export class Note implements ISynchronizable {
  id: number;
  investmentId: number;
  text = "";
  date = new Date();
  feId: number;
  syncState: SyncStateEnum = SyncStateEnum.userCreated;
  creationDate = new Date();
  isDeleted: boolean;
}
