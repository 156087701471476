import { RepositoryBase } from "./repositoryBase";
import { Note } from "@src/app/points/windows/info-tabs/notes/models/note";

class NoteRepositoryClass extends RepositoryBase<Note> {
  constructor() {
    super(Note, "Note");
  }
}

export const NoteRepository = new NoteRepositoryClass();
