<div class="left-menu">
  <mat-card class="user-card">
    <mat-card-header>
      <button mat-icon-button (click)="closeSidenav()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-card-header>
    <mat-card-content>
      <div class="user-login">Zalogowany: {{ login }}</div>
      <div class="user-role">Typ konta: {{ role }}</div>
    </mat-card-content>
  </mat-card>
  <div class="content">
    <app-main-menu-item
      label="Mapa"
      route="/points/map"
      icon="map"
    ></app-main-menu-item>
    <app-main-menu-item
      label="Home"
      route="/home"
      icon="home"
    ></app-main-menu-item>
    <!-- <app-main-menu-item label="Zapisane trasy" route="/points/route" icon="near_me"></app-main-menu-item>
    <app-main-menu-item label="Przejechane trasy" route="/points/ghost" icon="directions_car"></app-main-menu-item> -->
    <app-main-menu-item
      label="Kontrahenci"
      route="/points/counterparty"
      icon="supervisor_account"
    ></app-main-menu-item>
    <app-main-menu-item
      label="Oferty"
      route="/points/offerList"
      icon="local_offer"
    ></app-main-menu-item>
    <!-- <div class="app-main-menu-item">
      <button>
        <a href="mailto:biuro@naviBud.com" title="Prześlij nam opinię">
          <mat-icon>local_post_office</mat-icon><span>Prześlij opinię</span>
        </a>
      </button>
    </div> -->
    <app-main-menu-item label="Pomoc" route="/points/pomoc" icon="help">
    </app-main-menu-item>
    <!-- <mat-divider></mat-divider>
    <app-main-menu-item label="Wyloguj" icon="logout" (click)="logout()"></app-main-menu-item> -->
  </div>
</div>
