import { Injectable } from "@angular/core";
import Dexie from "dexie";

export interface IEntityWithKey<T> {
  feKey: number;
  value: T;
}

export interface IEntity {
  id?: number;
}

export interface IRepositoryBase<T extends IEntity> {
  dexieTable: Dexie.Table<T, number>;
}

export const NavibudDbName = "NaviBudDb";
export const TableNames = {
  FilterPreset: "FilterPrestet",
};

@Injectable()
export abstract class RepositoryBase<T extends IEntity>
  extends Dexie
  implements IRepositoryBase<T> {
  dexieTable: Dexie.Table<T, number>;
  type: new () => T;

  constructor(type: new () => T, tableName: string) {
    super(NavibudDbName);
    this.type = type;
    this.version(1).stores({
      GeoLocation: "++id",
      MapInvestment: "++id",
      Offer: "++id",
    });

    this.version(2).stores({
      GeoLocation: "++id",
      MapInvestment: "++id",
      Offer: "++id",
      FilterSettings: "++id",
    });

    this.version(3).stores({
      GeoLocation: "++id",
      MapInvestment: "++id",
      Offer: "++id",
      FilterSettings: "++id",
      PlannedRoute: "++id",
    });

    this.version(4).stores({
      GeoLocation: "++id",
      MapInvestment: "++id",
      Offer: "++id",
      FilterSettings: "++id",
      PlannedRoute: "++id",
      Event: "++id",
      Route: "++id",
    });

    this.version(5).stores({
      GeoLocation: "++feId",
      MapInvestment: "++feId",
      Offer: "++feId",
      FilterSettings: "++feId",
      PlannedRoute: "++feId",
      Event: "++feId",
      Route: "++feId",
    });

    this.version(6).stores({
      GeoLocation: "++feId",
      MapInvestment: "++feId",
      Offer: "++feId",
      Note: "++feId",
      FilterSettings: "++feId",
      PlannedRoute: "++feId",
      Event: "++feId",
      Route: "++feId",
    });

    this.version(7).stores({
      GeoLocation: "++feId",
      MapInvestment: "++feId",
      Offer: "++feId",
      Note: "++feId",
      FilterSettings: "++feId",
      PlannedRoute: "++feId",
      Event: "++feId",
      Route: "++feId",
      [TableNames.FilterPreset]: "++feId",
    });

    this.dexieTable = this.table(tableName);
  }

  getAll(): Promise<T[]> {
    return this.dexieTable
      .toArray()
      .then((items) => items.map((x) => Object.assign(new this.type(), x)));
  }

  search(searchFunc: (st: T) => boolean) {
    return this.dexieTable.filter(searchFunc).toArray();
  }

  add(item: T) {
    return this.dexieTable.add(item);
  }

  bulkAdd(items: Array<T>) {
    return this.dexieTable.bulkAdd(items);
  }

  put(data: T) {
    try {
      return this.dexieTable.put(data);
    } catch (e) {}
  }

  bulkPut(data) {
    return this.dexieTable.bulkPut(data);
  }

  update(id, item) {
    return this.dexieTable.update(id, item);
  }

  remove(id) {
    return this.dexieTable.delete(id);
  }

  getLast(): Promise<T> {
    return this.dexieTable.toCollection().last();
  }

  async listNotSynced(amount: number): Promise<Array<IEntityWithKey<T>>> {
    const itemsToSync: Array<{ feKey: number; value: T }> = [];
    await this.dexieTable
      .filter((x) => !x.id)
      .limit(amount)
      .each((x, cursor) => {
        itemsToSync.push({ feKey: cursor.primaryKey, value: x });
      });
    return itemsToSync;
  }
}
