import { Log } from "./log";
import axios from "axios";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem(tokenKey);
      localStorage.removeItem("user");
      location.reload();
    }
    return Promise.reject(error);
  }
);

export async function GET<T>(url: string) {
  const headers = getAuthHeaders();
  const response = await axios.get<T>(url, { headers });
  return response.data;
}

export async function POST<T>(url: string, body: any) {
  const headers = getAuthHeaders();
  const response = await axios.post<T>(url, body, { headers });
  return response.data;
}

export async function PUT<T>(url: string, body: any) {
  const headers = getAuthHeaders();
  const response = await axios.put<T>(url, body, { headers });
  return response.data;
}

export async function DELETE<T>(url: string) {
  const headers = getAuthHeaders();
  const response = await axios.delete<T>(url, { headers });
  return response.data;
}

const tokenKey = "authToken";
export function getAuthHeaders() {
  const token: string = localStorage.getItem(tokenKey);
  if (!token) {
    Log.Error(`Cannot initialize headers cause there is no token : |`);
    return;
  }

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return headers;
}
