import { Component, Input, OnInit } from "@angular/core";
import { MapInvestment } from "../../../models/investment";
import { InvestmentsService } from "../../../services/investmentsDomain.service";
import State from "../../../../State/appState";
import { InvestmentRepository } from "@src/app/points/dataAccess/investmentRepository";
import { busyIndicatorService } from "@src/app/auth/redux/busyIndicatorState";
import { SyncInvestmentServiceInstance } from "@src/app/points/dataAccess/sync/sync-investment.service";

@Component({
  selector: "app-investors",
  templateUrl: "./investors.component.html",
  styleUrls: ["./investors.component.min.css"],
})
export class InvestorsComponent implements OnInit {
  investment: MapInvestment;

  @Input() isNew = false;
  constructor(private investmentsService: InvestmentsService) {}

  canRenderImage() {
    return this.investment.imageUrls?.[0]?.url || false;
  }

  ngOnInit() {
    if (this.isNew) {
      this.investment = new MapInvestment();
      return;
    }
    State.mapState.value.selectedInvestment.subscribe((x) => {
      if (!x) {
        return;
      }
      this.investment = x;
    });
  }

  addNewInvestment() {
    if (!this.investment.lng || !this.investment.lat) {
      alert("Szerokosc i dlugosc geograficzna sa wymagane.");
      return;
    }
    this.investmentsService.createNew(this.investment, () => {
      alert("Inwestycja dodana");
      this.investment = new MapInvestment();
    });
  }

  async onImageChanged(event) {
    const key = "images changed";
    if (!this.investment.id) {
      busyIndicatorService.setBusy(key);
      await SyncInvestmentServiceInstance.createInvestment(this.investment);
      const newValue = await InvestmentRepository.dexieTable.get(
        this.investment.feId
      );
      State.mapState.value.selectedInvestment.next(newValue);
      busyIndicatorService.setFree(key);
    }

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        if (!this.investment.imageUrls) {
          this.investment.imageUrls = [];
        }
        this.investment.imageUrls[0] = {
          url: reader.result as any,
          isDeleted: false,
        };
        this.investmentsService.uploadFile(
          event.target.files[0],
          this.investment.id
        );
        // State.mapState.value.selectedMarker.next(this.selectedMarker);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }
  }
}
