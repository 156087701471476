import Dexie from "dexie";
import { RepositoryBase } from "./repositoryBase";
import {
  ISynchronizable,
  SyncStateEnum,
} from "@src/app/points/dataAccess/sync/ISynchronizable";
import { MapInvestment } from "@src/app/points/models/investment";

class PlanedRouteRepository extends RepositoryBase<PlannedRoute> {
  mapInvestmentTable: Dexie.Table<PlannedRoute, number>;

  constructor() {
    super(PlannedRoute, "PlannedRoute");
  }
}

export class PlannedRoute implements ISynchronizable {
  id?: number;
  userId?: string;
  name: string;
  route: MapInvestment[];
  wasSavedToBe = false;
  lastUpdate?: string;
  wasSyncedWithBe?: boolean;
  syncState: SyncStateEnum;
  feId: number;
  isDeleted: boolean;
}

export const PlanedRouteRepositoryInstance = new PlanedRouteRepository();
