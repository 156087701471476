import { CalendarEvent } from "../models/event";
import { RepositoryBase } from "@src/app/points/dataAccess/repositoryBase";

export class CalendarRepositoryClass extends RepositoryBase<CalendarEvent> {
  constructor() {
    super(CalendarEvent, "Event");
  }
}

export const CalendarRepository = new CalendarRepositoryClass();
