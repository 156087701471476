import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["../../../../assets/styles/shared/auth.scss"],
})
export class ResetPasswordComponent implements OnInit {
  email: string;
  token: string;
  password: string; // renamed from newPassword to password for better understanding
  confirmPassword: string; // new property for confirmPassword
  errorMessage: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.email = params["email"];
      this.token = params["token"];
    });
  }

  onSubmit() {
    if (!this.password || !this.confirmPassword) {
      this.errorMessage = "You must provide a new password and confirm it.";
      return;
    }

    if (this.password !== this.confirmPassword) {
      this.errorMessage =
        "The new password and confirmation password do not match.";
      return;
    }

    this.authService
      .resetPassword(this.email, this.token, this.password)
      .subscribe(
        (data) => {
          // password reset was successful, redirect user to login page
          this.router.navigate(["/login"]);
        },
        (error) => {
          // display error message
          this.errorMessage = error.message;
        }
      );
  }

  toLogin() {
    // navigate to login
    this.router.navigate(["/login"]);
  }
}
