import { Injectable } from "@angular/core";
import Dexie from "dexie";
import { Route } from "../models/route";
import { RepositoryBase } from "@src/app/points/dataAccess/repositoryBase";
import { CalendarEvent } from "@src/app/points/models/event";

@Injectable()
export class RouteRepository extends RepositoryBase<Route> {
  constructor() {
    super(Route, "Route");
  }
}
