<div>
  <form class="form-info">
    <img
      *ngIf="canRenderImage()"
      class="image"
      [src]="investment.imageUrls[0].url"
    />
    <input
      style="display: none"
      type="file"
      (change)="onImageChanged($event)"
      #fileInput
    />
    <button (click)="fileInput.click()">Dodaj zdjęcie</button>
    <div class="app-invest-info">
      <app-investor-info
        [IsNew]="isNew"
        [investment]="investment"
      ></app-investor-info>
      <app-investment-info [investment]="investment"></app-investment-info>
    </div>
    <div *ngIf="isNew">
      <mat-form-field class="full-width">
        <input
          matInput
          name="lat"
          type="number"
          placeholder="Szerokość geograficzna"
          [(ngModel)]="investment.lat"
          autofocus
        />
      </mat-form-field>
      <mat-form-field class="full-width">
        <input
          matInput
          name="lng"
          type="number"
          placeholder="Długość geograficzna"
          [(ngModel)]="investment.lng"
          autofocus
        />
      </mat-form-field>
      <button (click)="addNewInvestment()">Dodaj</button>
    </div>
  </form>
</div>
