import { Injectable } from "@angular/core";
import { MapInvestment } from "../../models/investment";
import State from "@src/app/State/appState";
import { OnDestroyBase } from "@src/app/common/OnDestroyBase";
import { Store } from "@ngrx/store";
import { INaviStore } from "@src/app/State/RootReducer";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class NaviPointsListService extends OnDestroyBase {
  availableIndexes: Array<number> = [];
  private alphabet: Array<string> = [];

  constructor(private store: Store<INaviStore>) {
    super();
    this.resetAlphabet();
  }

  reset() {
    State.mapState.value.navigationList.next([]);
  }

  resetAlphabet() {
    this.alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    const len = this.alphabet.length;
    for (let i = 0; i < len; i++) {
      for (let j = 0; j < len; j++) {
        this.alphabet = [...this.alphabet, this.alphabet[i] + this.alphabet[j]];
      }
    }
  }

  async addOrRemoveFromNaviHandler(investment: MapInvestment) {
    if (this.isNaviMarker(investment)) {
      await this.removeNaviPoint(investment);
    } else {
      await this.addToNavigation(investment);
    }
  }

  isNaviMarker(investment: MapInvestment) {
    return this.naviPointList().some((x) => x === investment);
  }

  getIndexOfItemInvestment(data: any): number {
    for (let i = 0; i < this.getNaviPointListLength(); i++) {
      if (data.fullAddress === this.naviPointList()[i].fullAddress) {
        return i;
      }
    }
    return -1;
  }

  async addToNavigation(mapInvestment: MapInvestment) {
    const selectedInvestment = State.mapState.value.selectedInvestment.value;
    const markers = State.mapState.value.markers.value;
    const selectedOldStyleMarker = State.mapState.value.selectedMarker.value;
    const selectedMarker = markers.find(
      (x) => x.get("id") === selectedOldStyleMarker.get("id")
    );

    this.selected = selectedMarker;
    mapInvestment.seqNum = this.getNextAvailableSeqNum();
    const sequencedMarkerUrl = this.getNaviMarkerIcon(
      selectedInvestment.seqNum
    );
    const image = {
      url: sequencedMarkerUrl,
      scaledSize: new google.maps.Size(40, 40),
    };
    selectedMarker.setIcon(image);
    selectedInvestment.overriddenImage = sequencedMarkerUrl;
    State.mapState.value.navigationList.next([
      ...this.naviPointList(),
      mapInvestment,
    ]);
  }

  private selected: google.maps.Marker;

  async removeNaviPoint(investment: MapInvestment) {
    investment.overriddenImage = "";
    const markers = State.mapState.value.markers.value;
    const selectedMarker = markers.find((x) => x.get("id") === investment.id); // no idea why there is more than 1

    const image = {
      url: MapInvestment.getMarkerImage(investment),
      scaledSize: new google.maps.Size(40, 40),
    };
    selectedMarker.setIcon(image);

    investment.overriddenImage = null;

    State.mapState.value.navigationList.next(
      this.naviPointList().filter((x) => x !== investment)
    );
  }

  getNextAvailableSeqNum(): string {
    if (this.getNaviPointListLength() > 0) {
      return this.alphabet[this.getNaviPointListLength()];
    } else {
      return this.alphabet[0];
    }
  }

  handleChangePosition(data: any): void {
    const spaceindex = data.el.innerText.indexOf(" ");
    const changedSymbol = data.el.innerText.substring(0, spaceindex);
    let removedItem: any;
    for (let i = 0; i < this.naviPointList().length; i++) {
      if (this.naviPointList()[i].seqNum === changedSymbol) {
        removedItem = this.naviPointList().splice(i, 1)[0];
      }
    }
    if (removedItem !== undefined) {
      for (let i = 0; i < data.target.children.length; i++) {
        const item = data.target.children[i];
        const spaceindex2 = item.innerText.indexOf(" ");
        if (item.innerText.substring(0, spaceindex2) === changedSymbol) {
          this.naviPointList().splice(i - 1, 0, removedItem);
        }
      }
    }
  }

  public getNaviMarkerIcon(iconLetter: string): string {
    return (
      "data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2238%22%20height%3D%2238%22%20viewBox%3D%220%200%2038%2038%22%3E%3Cpath%20fill%3D%22%23808080%22%20stroke%3D%22%23ccc%22%20stroke-width%3D%22.5%22%20d%3D%22M34.305" +
      "%2016.234c0%208.83-15.148%2019.158-15.148%2019.158S3.507%2025.065%203.507%2016.1c0-8.505%206.894-14.304%2015.4-14.304%208.504%200%2015.398%205.933%2015.398%2014.438z%22%2F%3E%3Ctext%20transform%3D%22translate%2819%2018.5%29%22%20fill%3D%22%23fff%22%20style%3D%22font" +
      "-family%3A%20Arial%2C%20sans-serif%3Bfont-weight%3Abold%3Btext-align%3Acenter%3B%22%20font-size%3D%2212%22%20text-anchor%3D%22middle%22%3E" +
      iconLetter +
      "%3C%2Ftext%3E%3C%2Fsvg%3E"
    );
  }

  public getSign(id: number): string {
    const sign: string = this.alphabet[id];
    return sign;
  }

  public getNaviPointListLength(): number {
    return this.naviPointList().length;
  }

  naviPointList() {
    return State.mapState.value.navigationList.value;
  }
}
