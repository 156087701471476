import { from, of } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";
import { offerActions } from "@src/app/points/windows/info-tabs/offer/offer.slice";
import { Offer } from "@src/app/points/windows/info-tabs/offer/models/offer";
import State from "@src/app/State/appState";
import { SyncInvestmentServiceInstance } from "@src/app/points/dataAccess/sync/sync-investment.service";
import { OfferRepositoryInstance } from "@src/app/points/dataAccess/offerRepository";
import { SyncOfferServiceInstance } from "@src/app/points/dataAccess/sync/sync-offer.service";

export class AppEffects {
  constructor() {}
}
