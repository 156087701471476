import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { sessionService } from "src/app/core/services/session.service";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let bodyLength = JSON.stringify(request.body).length / 1024 / 1024;
    if (bodyLength > 5) throw "Request too long - search text in code";
    const token: string = localStorage.getItem("authToken");
    if (token) {
      request = request.clone({
        headers: request.headers.set("Authorization", "Bearer " + token),
      });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log("error", error);
        if (error.status === 401) {
            sessionService.logoutEndOfSession();
        }
        return throwError(error);
      })
    );
  }
}
