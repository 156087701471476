import {
  apiUrl,
  createSyncEndpoints,
  ISynchronizableUrl,
} from "@src/app/endPoints/endPoints";

interface IOfferEndpoint extends ISynchronizableUrl {
  baseUrl: string;
  createWithFiles: string;
}

const base = `${apiUrl()}/Offer`;
const syncEndpoints = createSyncEndpoints(base);

export const OfferEndpoint: IOfferEndpoint = {
  baseUrl: base,
  ...syncEndpoints,
  createWithFiles: `${base}/createWithFiles`,
};
