<div class="heading-box">
  <mat-icon aria-hidden="false" aria-label="Dane Inwestora" fontIcon="person_outline"></mat-icon>
  <h2>Dane Inwestora:</h2>
</div>

<div class="investor-type">
  <mat-form-field class="full-width">
    <mat-label>Typ investora</mat-label>
    <mat-select [(value)]="investment.investorType" name="food">
      <mat-option
        *ngFor="let investorType of investorTypes"
        [value]="investorType"
      >
        {{ investorType }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="!isPrivatePerson()" class="full-width">
    <input
      matInput
      class="big-input"
      name="investorFullName"
      type="text"
      placeholder="Nazwa inwestora"
      value="{{ getFullName }}"
      autofocus
    />
  </mat-form-field>

  <mat-form-field *ngIf="!isPrivatePerson()" class="full-width">
    <input
      matInput
      name="NIP"
      type="text"
      placeholder="NIP"
      [(ngModel)]="investment.nip"
      required
      autofocus
    />
  </mat-form-field>

  <mat-form-field *ngIf="!isPrivatePerson()" class="full-width">
    <input
      matInput
      name="REGON"
      type="text"
      placeholder="REGON"
      [(ngModel)]="investment.regon"
      required
      autofocus
    />
  </mat-form-field>

  <mat-form-field *ngIf="!isCompany()" class="full-width">
    <input
      matInput
      name="investorFirstName"
      type="text"
      placeholder="Imię inwestora"
      [(ngModel)]="investment.investorFirstName"
      autofocus
    />
  </mat-form-field>

  <mat-form-field *ngIf="!isCompany()" class="full-width">
    <input
      matInput
      name="investorSurname"
      type="text"
      placeholder="Nazwisko inwestora"
      [(ngModel)]="investment.investorLastName"
      autofocus
    />
  </mat-form-field>

  <div class="full-width display-flex">
    <mat-form-field class="full-width">
      <input
        matInput
        name="investorPhone"
        class="w-90"
        type="tel"
        placeholder="Numer telefonu"
        [(ngModel)]="investment.investorPhone"
        autofocus
      />
    </mat-form-field>
    <button *ngIf="!IsNew" mat-icon-button color="primary" (click)="call()">
      <mat-icon aria-label="Zadzwoń">phone</mat-icon>
    </button>
  </div>

  <div class="full-width display-flex">
    <mat-form-field class="full-width">
      <input
        matInput
        name="investorEmail"
        class="w-90"
        type="email"
        placeholder="E-mail inwestora"
        [(ngModel)]="investment.investorEmail"
        autofocus
      />
    </mat-form-field>
    <button *ngIf="!IsNew" mat-icon-button color="primary" (click)="sendMail()">
      <mat-icon aria-label="Wyślij maila">email</mat-icon>
    </button>
  </div>

  <mat-form-field class="full-width">
    <input
      matInput
      name="street"
      type="text"
      placeholder="Ulica"
      [(ngModel)]="investment.investorStreetName"
      required
      autofocus
    />
  </mat-form-field>

  <mat-form-field class="full-width">
    <input
      matInput
      name="city"
      type="text"
      placeholder="Miasto"
      [(ngModel)]="investment.investorCity"
      required
      autofocus
    />
  </mat-form-field>

  <mat-form-field class="full-width">
    <input
      matInput
      name="postCode"
      type="text"
      placeholder="Kod pocztowy"
      [(ngModel)]="investment.investorPostcode"
      required
      autofocus
    />
  </mat-form-field>
</div>
