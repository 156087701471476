import { authReducer, IAuthState } from "@src/app/auth/redux/authState";
import { ILastSyncInfo, syncStateService } from "@src/app/State/lastSyncState";
import {
  CalendarReducer,
  ICalendarState,
} from "@src/app/auth/redux/calendarState";
import {
  IInvestmentState,
  investmentReducer,
} from "@src/app/auth/redux/investmentState";
import { InfoWindowReducer } from "@src/app/points/windows/info-tabs/info-tabs-reducer";
import {
  IOfferState,
  OfferReducer,
} from "@src/app/points/windows/info-tabs/offer/offer.slice";
import {
  INoteState,
  noteReducer,
} from "@src/app/points/windows/info-tabs/notes/slice-Notes-Tab";
import { IUserState, userReducer } from "@src/app/State/user-slice";

export interface INaviStore {
  auth: IAuthState;
  calendar: ICalendarState;
  investments: IInvestmentState;
  offer: IOfferState;
  note: INoteState;
  user: IUserState;
}

export const RootReducer = {
  auth: authReducer,
  calendar: CalendarReducer,
  investments: investmentReducer,
  infoWindow: InfoWindowReducer,
  offer: OfferReducer,
  note: noteReducer,
  user: userReducer,
};
