<ngx-ui-loader [loaderId]="'map-loader'"></ngx-ui-loader>

<mat-sidenav-container class="app-sidenav-container" (backdropClick)="closeSidenav()">
  <mat-sidenav #sidenav class="app-menu-container" [opened]="sidenavOpened" (keydown.escape)="closeSidenav()">
    <app-main-menu></app-main-menu>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar class="main-toolbar" color="primary">
      <mat-toolbar-row>
        <div class="main-toolbar-left">
          <button class="menu-button" mat-icon-button (click)="toggleSidenav()">
            <mat-icon>menu</mat-icon>
          </button>
          <a class="logo" href="/">
            <div class="">
              <img class="logo-image" src="/assets/logo.png" />
            </div>
          </a>
        </div>
        <div class="main-toolbar-user">
          <div class="toolbar-user-data">
            <button mat-mini-fab class="user-settings-button" [matMenuTriggerFor]="menu">
              <mat-icon>person</mat-icon>
            </button>
          </div>
          <!-- <div class="toolbar-user-settings"> -->
            <mat-menu #menu="matMenu" class="user-settings-menu">
              <div class="user-data">Zalogowany: {{ login }}</div>
              <app-main-menu-item label="Zarządzaj kontem" route="user/data"
                icon="manage_accounts"></app-main-menu-item>
              <mat-divider></mat-divider>
              <app-main-menu-item label="Wyloguj" icon="logout" (click)="logout()"></app-main-menu-item>
            </mat-menu>
          <!-- </div> -->
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>