import Dexie from "dexie";
import { MapInvestment } from "../models/investment";
import { RepositoryBase } from "./repositoryBase";

class InvestmentRepositoryClass extends RepositoryBase<MapInvestment> {
  mapInvestmentTable: Dexie.Table<MapInvestment, number>;

  constructor() {
    super(MapInvestment, "MapInvestment");
  }

  getAll(): Promise<MapInvestment[]> {
    return this.dexieTable
      .toArray()
      .then((items) => items.map((x) => {
        const obj = Object.assign(new this.type(), x);
        return obj;
      }));
  }
}

export const InvestmentRepository = new InvestmentRepositoryClass();
