import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum infoWindowStatusEnum {
  none = "none",
  editable = "editable",
  small = "small",
}

export interface InfoWindowState {
  status: infoWindowStatusEnum;
  height: number;
}

const initState: InfoWindowState = {
  status: infoWindowStatusEnum.none,
  height: 0,
};

const slice = createSlice({
  name: "InfoWindowReducer",
  initialState: initState,
  reducers: {
    setInfoWindow: (
      state,
      p: PayloadAction<{
        infoWindowStatus: infoWindowStatusEnum;
        infoWindowHeight: number;
      }>
    ) => {
      state.status = p.payload.infoWindowStatus;
      state.height = p.payload.infoWindowHeight;
      return state;
    },
  },
});

export const InfoWindowReducer = slice.reducer;
export const { setInfoWindow } = slice.actions;
