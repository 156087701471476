import { ISynchronizable, SyncStateEnum } from "./ISynchronizable";
import { RepositoryBase } from "../repositoryBase";
import { ISynchronizableUrl } from "../../../endPoints/endPoints";
import { SyncGenericWithStream } from "./GenericSync";
import { DELETE, POST, PUT } from "../../../../utils/HTTP";
import { BehaviorSubject } from "rxjs";
import { waitForObject } from "@src/utils/utils";
import _ from "lodash";

export abstract class EntityServiceBase<T extends ISynchronizable> {
  constructor(
    private repository: RepositoryBase<T>,
    private endpoints: ISynchronizableUrl,
    private lastSyncDate: Date,
    private type: new () => T,
    private setLastUpdate: () => void
  ) {}

  items = new BehaviorSubject<T[]>([]);
  inSync = new BehaviorSubject(false);
  itemsValue: () => T[] = () => this.items.value;

  async loadAll() {
    await waitForObject(this.inSync);
    const all = await this.repository.getAll();
    this.items.next(all);
  }

  async add(item: T) {
    const clone: T = {
      ..._.cloneDeep(item),
      id: undefined,
      feId: undefined,
      syncState: SyncStateEnum.newInUi,
    };

    const backendValue = await POST<T>(this.endpoints.createRange, [clone]);
    await this.repository.add(backendValue);
    this.items.next([...this.items.value, clone]);
  }

  async update(item: T) {
    await this.repository.update(item.feId, item);
    await PUT(this.endpoints.updateRange, [item]);
    const nextState = this.items.value.map((x) => {
      if (x.id === item.id) {
        return { ...item, feId: item.feId };
      }
      return x;
    });
    this.items.next(nextState);
  }

  async delete(items: T[]) {
    const feIdsToDelete = new Set(items.map((x) => x.feId));
    for (const feId of feIdsToDelete) {
      await this.repository.remove(feId);
    }

    const query = items.map((num) => "ids=" + num.id).join("&");
    await DELETE(`${this.endpoints.deleteRange}?${query}`);

    const nextState = this.items.value.filter(
      (item) => !feIdsToDelete.has(item.feId)
    );
    this.items.next(nextState);
  }

  async SyncGenericWithStream() {
    await SyncGenericWithStream<T>({
      type: this.type,
      repository: this.repository,
      endpoint: this.endpoints,
      query: { lastSyncDate: new Date(this.lastSyncDate) },
    });
    this.setLastUpdate();
    this.inSync.next(true);
  }
}
