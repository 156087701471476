import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "@src/app/auth/services/auth.service";
import { OnDestroyBase } from "@src/app/common/OnDestroyBase";
import { UserService } from "@src/app/auth/services/user.service";
import { sessionService } from "@src/app/core/services/session.service";

export class User {
  user: "";
  email: "";
  oldPassword: "";
  newPassword: "";
  confirmedPassword: "";
}

@Component({
  selector: "app-user-password",
  templateUrl: "./user-password.component.html",
  styleUrls: ["./user-password.component.scss"],
})
export class UserPasswordComponent extends OnDestroyBase {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  currentUser: string;
  passwordFormSubmitted = false;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UserPasswordComponent>
  ) {
    super();
  }

  ngOnInit() {
    this.currentUser = this.userService.user.login;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  changePassword() {
    this.passwordFormSubmitted = true;
    if (!this.oldPassword || !this.newPassword || !this.confirmPassword) return;

    if (this.newPassword !== this.confirmPassword) {
      this._snackBar.open(
        "Nowe hasło i jego potwierdzenie nie są takie same. Spróbuj ponownie.",
        "Zamknij",
        {
          duration: 3000,
          verticalPosition: "top",
          panelClass: ["custom-snackbar"],
        }
      );
      return;
    }

    this.authService
      .changePassword(this.currentUser, this.oldPassword, this.newPassword)
      .subscribe(
        (result) => {
          const successMessage =
            result.message +
            " Ze względów bezpieczeństwa za chwilę zostaniesz wylogowany.";
          this._snackBar.open(successMessage, "Zamknij", {
            duration: 5000,
            verticalPosition: "top",
            panelClass: ["custom-snackbar"],
          });
          this.oldPassword = "";
          this.newPassword = "";
          this.confirmPassword = "";
          this.passwordFormSubmitted = false;
          // Wait for 5 seconds (5000 milliseconds) before logging the user out
          setTimeout(() => {
            localStorage.clear();
            sessionStorage.clear();
            sessionService.logoutEndOfSession();
          }, 5000);
        },
        (error) => {
          const message = error.error.message
            ? error.error.message
            : "Wystąpił błąd podczas zmiany hasła.";
          this._snackBar.open(message, "Zamknij", {
            duration: 3000,
            verticalPosition: "top",
            panelClass: ["custom-snackbar"],
          });
        }
      );
  }
}
