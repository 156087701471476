// source: Investments.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.investments_reply_grpc', null, global);
goog.exportSymbol('proto.investments_request_grpc', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.investments_request_grpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.investments_request_grpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.investments_request_grpc.displayName = 'proto.investments_request_grpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.investments_reply_grpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.investments_reply_grpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.investments_reply_grpc.displayName = 'proto.investments_reply_grpc';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.investments_request_grpc.prototype.toObject = function(opt_includeInstance) {
  return proto.investments_request_grpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.investments_request_grpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.investments_request_grpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    lastSyncDate: (f = msg.getLastSyncDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.investments_request_grpc}
 */
proto.investments_request_grpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.investments_request_grpc;
  return proto.investments_request_grpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.investments_request_grpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.investments_request_grpc}
 */
proto.investments_request_grpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSyncDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.investments_request_grpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.investments_request_grpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.investments_request_grpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.investments_request_grpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLastSyncDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.investments_request_grpc.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.investments_request_grpc} returns this
 */
proto.investments_request_grpc.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.investments_request_grpc.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.investments_request_grpc} returns this
 */
proto.investments_request_grpc.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double latitude = 3;
 * @return {number}
 */
proto.investments_request_grpc.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.investments_request_grpc} returns this
 */
proto.investments_request_grpc.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp last_sync_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.investments_request_grpc.prototype.getLastSyncDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.investments_request_grpc} returns this
*/
proto.investments_request_grpc.prototype.setLastSyncDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_request_grpc} returns this
 */
proto.investments_request_grpc.prototype.clearLastSyncDate = function() {
  return this.setLastSyncDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_request_grpc.prototype.hasLastSyncDate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.investments_reply_grpc.prototype.toObject = function(opt_includeInstance) {
  return proto.investments_reply_grpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.investments_reply_grpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.investments_reply_grpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: (f = msg.getUserId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    lastUpdate: (f = msg.getLastUpdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    baseInvestmentId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    identifier: (f = msg.getIdentifier()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    investorLastName: (f = msg.getInvestorLastName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    fullAddress: (f = msg.getFullAddress()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    streetName: (f = msg.getStreetName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    streetNumber: (f = msg.getStreetNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    investorFirstName: (f = msg.getInvestorFirstName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    investorCity: (f = msg.getInvestorCity()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    investorPostcode: (f = msg.getInvestorPostcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    investorStreetName: (f = msg.getInvestorStreetName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    postcode: (f = msg.getPostcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    city: (f = msg.getCity()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    commune: (f = msg.getCommune()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    district: (f = msg.getDistrict()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    plotNumber: (f = msg.getPlotNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    permissionNumber: (f = msg.getPermissionNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    intentType: (f = msg.getIntentType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    permissionDate: (f = msg.getPermissionDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isVisited: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    lng: jspb.Message.getFieldWithDefault(msg, 25, ""),
    lat: jspb.Message.getFieldWithDefault(msg, 26, ""),
    sellStatus: (f = msg.getSellStatus()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    buildingStatus: (f = msg.getBuildingStatus()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    investorEmail: (f = msg.getInvestorEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    investorPhone: (f = msg.getInvestorPhone()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    nextContactDate: (f = msg.getNextContactDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    state: (f = msg.getState()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    county: (f = msg.getCounty()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    community: (f = msg.getCommunity()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    precinct: (f = msg.getPrecinct()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    plot: (f = msg.getPlot()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    importDate: (f = msg.getImportDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    registrationUnit: (f = msg.getRegistrationUnit()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    mapNo: (f = msg.getMapNo()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    terc: (f = msg.getTerc()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    nip: (f = msg.getNip()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    regon: (f = msg.getRegon()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isFenced: (f = msg.getIsFenced()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    sellChances: jspb.Message.getFieldWithDefault(msg, 44, 0),
    products: (f = msg.getProducts()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    resignationReason: (f = msg.getResignationReason()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    investorType: (f = msg.getInvestorType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.investments_reply_grpc}
 */
proto.investments_reply_grpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.investments_reply_grpc;
  return proto.investments_reply_grpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.investments_reply_grpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.investments_reply_grpc}
 */
proto.investments_reply_grpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBaseInvestmentId(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setIdentifier(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInvestorLastName(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFullAddress(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStreetName(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStreetNumber(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInvestorFirstName(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInvestorCity(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInvestorPostcode(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInvestorStreetName(value);
      break;
    case 15:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPostcode(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCity(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCommune(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDistrict(value);
      break;
    case 19:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPlotNumber(value);
      break;
    case 20:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 21:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPermissionNumber(value);
      break;
    case 22:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setIntentType(value);
      break;
    case 23:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPermissionDate(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsVisited(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setLng(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setLat(value);
      break;
    case 27:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSellStatus(value);
      break;
    case 28:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBuildingStatus(value);
      break;
    case 29:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInvestorEmail(value);
      break;
    case 30:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInvestorPhone(value);
      break;
    case 31:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setNextContactDate(value);
      break;
    case 32:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setState(value);
      break;
    case 33:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCounty(value);
      break;
    case 34:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCommunity(value);
      break;
    case 35:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrecinct(value);
      break;
    case 36:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPlot(value);
      break;
    case 37:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setImportDate(value);
      break;
    case 38:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRegistrationUnit(value);
      break;
    case 39:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMapNo(value);
      break;
    case 40:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTerc(value);
      break;
    case 41:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNip(value);
      break;
    case 42:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRegon(value);
      break;
    case 43:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setIsFenced(value);
      break;
    case 44:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSellChances(value);
      break;
    case 45:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setProducts(value);
      break;
    case 46:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setResignationReason(value);
      break;
    case 47:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInvestorType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.investments_reply_grpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.investments_reply_grpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.investments_reply_grpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.investments_reply_grpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsDeleted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getLastUpdate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBaseInvestmentId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getIdentifier();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getInvestorLastName();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFullAddress();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStreetName();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStreetNumber();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getInvestorFirstName();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getInvestorCity();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getInvestorPostcode();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getInvestorStreetName();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPostcode();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCommune();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDistrict();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPlotNumber();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPermissionNumber();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIntentType();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPermissionDate();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsVisited();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getLng();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getLat();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getSellStatus();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBuildingStatus();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getInvestorEmail();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getInvestorPhone();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNextContactDate();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCounty();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCommunity();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrecinct();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPlot();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getImportDate();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRegistrationUnit();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMapNo();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTerc();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNip();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRegon();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsFenced();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSellChances();
  if (f !== 0) {
    writer.writeInt32(
      44,
      f
    );
  }
  f = message.getProducts();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getResignationReason();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getInvestorType();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.investments_reply_grpc.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue user_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getUserId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_deleted = 3;
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp last_update = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.investments_reply_grpc.prototype.getLastUpdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setLastUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearLastUpdate = function() {
  return this.setLastUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasLastUpdate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 base_investment_id = 5;
 * @return {number}
 */
proto.investments_reply_grpc.prototype.getBaseInvestmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.setBaseInvestmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.StringValue identifier = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getIdentifier = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearIdentifier = function() {
  return this.setIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasIdentifier = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue investor_last_name = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getInvestorLastName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setInvestorLastName = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearInvestorLastName = function() {
  return this.setInvestorLastName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasInvestorLastName = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue full_address = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getFullAddress = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setFullAddress = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearFullAddress = function() {
  return this.setFullAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasFullAddress = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue street_name = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getStreetName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setStreetName = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearStreetName = function() {
  return this.setStreetName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasStreetName = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue street_number = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getStreetNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setStreetNumber = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearStreetNumber = function() {
  return this.setStreetNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasStreetNumber = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue investor_first_name = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getInvestorFirstName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setInvestorFirstName = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearInvestorFirstName = function() {
  return this.setInvestorFirstName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasInvestorFirstName = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue investor_city = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getInvestorCity = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setInvestorCity = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearInvestorCity = function() {
  return this.setInvestorCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasInvestorCity = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue investor_postcode = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getInvestorPostcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setInvestorPostcode = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearInvestorPostcode = function() {
  return this.setInvestorPostcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasInvestorPostcode = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.StringValue investor_street_name = 14;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getInvestorStreetName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 14));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setInvestorStreetName = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearInvestorStreetName = function() {
  return this.setInvestorStreetName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasInvestorStreetName = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.StringValue postcode = 15;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getPostcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 15));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setPostcode = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearPostcode = function() {
  return this.setPostcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasPostcode = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.StringValue city = 16;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getCity = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasCity = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.StringValue commune = 17;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getCommune = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setCommune = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearCommune = function() {
  return this.setCommune(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasCommune = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.StringValue district = 18;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getDistrict = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 18));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setDistrict = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearDistrict = function() {
  return this.setDistrict(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasDistrict = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.StringValue plot_number = 19;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getPlotNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 19));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setPlotNumber = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearPlotNumber = function() {
  return this.setPlotNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasPlotNumber = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.StringValue description = 20;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 20));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.StringValue permission_number = 21;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getPermissionNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 21));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setPermissionNumber = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearPermissionNumber = function() {
  return this.setPermissionNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasPermissionNumber = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional google.protobuf.StringValue intent_type = 22;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getIntentType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 22));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setIntentType = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearIntentType = function() {
  return this.setIntentType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasIntentType = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.protobuf.Timestamp permission_date = 23;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.investments_reply_grpc.prototype.getPermissionDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 23));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setPermissionDate = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearPermissionDate = function() {
  return this.setPermissionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasPermissionDate = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional bool is_visited = 24;
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.getIsVisited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.setIsVisited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional string lng = 25;
 * @return {string}
 */
proto.investments_reply_grpc.prototype.getLng = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.setLng = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string lat = 26;
 * @return {string}
 */
proto.investments_reply_grpc.prototype.getLat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.setLat = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional google.protobuf.StringValue sell_status = 27;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getSellStatus = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 27));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setSellStatus = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearSellStatus = function() {
  return this.setSellStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasSellStatus = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional google.protobuf.StringValue building_status = 28;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getBuildingStatus = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 28));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setBuildingStatus = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearBuildingStatus = function() {
  return this.setBuildingStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasBuildingStatus = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional google.protobuf.StringValue investor_email = 29;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getInvestorEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 29));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setInvestorEmail = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearInvestorEmail = function() {
  return this.setInvestorEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasInvestorEmail = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional google.protobuf.StringValue investor_phone = 30;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getInvestorPhone = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 30));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setInvestorPhone = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearInvestorPhone = function() {
  return this.setInvestorPhone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasInvestorPhone = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional google.protobuf.Timestamp next_contact_date = 31;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.investments_reply_grpc.prototype.getNextContactDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 31));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setNextContactDate = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearNextContactDate = function() {
  return this.setNextContactDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasNextContactDate = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional google.protobuf.StringValue state = 32;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getState = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 32));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setState = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearState = function() {
  return this.setState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasState = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional google.protobuf.StringValue county = 33;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getCounty = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 33));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setCounty = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearCounty = function() {
  return this.setCounty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasCounty = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional google.protobuf.StringValue community = 34;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getCommunity = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 34));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setCommunity = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearCommunity = function() {
  return this.setCommunity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasCommunity = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional google.protobuf.StringValue precinct = 35;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getPrecinct = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 35));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setPrecinct = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearPrecinct = function() {
  return this.setPrecinct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasPrecinct = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional google.protobuf.StringValue plot = 36;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getPlot = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 36));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setPlot = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearPlot = function() {
  return this.setPlot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasPlot = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional google.protobuf.Timestamp import_date = 37;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.investments_reply_grpc.prototype.getImportDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 37));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setImportDate = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearImportDate = function() {
  return this.setImportDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasImportDate = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional google.protobuf.StringValue registration_unit = 38;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getRegistrationUnit = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 38));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setRegistrationUnit = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearRegistrationUnit = function() {
  return this.setRegistrationUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasRegistrationUnit = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional google.protobuf.StringValue map_no = 39;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getMapNo = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 39));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setMapNo = function(value) {
  return jspb.Message.setWrapperField(this, 39, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearMapNo = function() {
  return this.setMapNo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasMapNo = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional google.protobuf.StringValue terc = 40;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getTerc = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 40));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setTerc = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearTerc = function() {
  return this.setTerc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasTerc = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional google.protobuf.StringValue nip = 41;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getNip = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 41));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setNip = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearNip = function() {
  return this.setNip(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasNip = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional google.protobuf.StringValue regon = 42;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getRegon = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 42));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setRegon = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearRegon = function() {
  return this.setRegon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasRegon = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional google.protobuf.StringValue is_fenced = 43;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getIsFenced = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 43));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setIsFenced = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearIsFenced = function() {
  return this.setIsFenced(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasIsFenced = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional int32 sell_chances = 44;
 * @return {number}
 */
proto.investments_reply_grpc.prototype.getSellChances = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 44, 0));
};


/**
 * @param {number} value
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.setSellChances = function(value) {
  return jspb.Message.setProto3IntField(this, 44, value);
};


/**
 * optional google.protobuf.StringValue products = 45;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getProducts = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 45));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setProducts = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearProducts = function() {
  return this.setProducts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasProducts = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional google.protobuf.StringValue resignation_reason = 46;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getResignationReason = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 46));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setResignationReason = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearResignationReason = function() {
  return this.setResignationReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasResignationReason = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional google.protobuf.StringValue investor_type = 47;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.investments_reply_grpc.prototype.getInvestorType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 47));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.investments_reply_grpc} returns this
*/
proto.investments_reply_grpc.prototype.setInvestorType = function(value) {
  return jspb.Message.setWrapperField(this, 47, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.investments_reply_grpc} returns this
 */
proto.investments_reply_grpc.prototype.clearInvestorType = function() {
  return this.setInvestorType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.investments_reply_grpc.prototype.hasInvestorType = function() {
  return jspb.Message.getField(this, 47) != null;
};


goog.object.extend(exports, proto);
