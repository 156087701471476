import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SchedulerComponent } from "./scheduler/scheduler.component";
import { FullCalendarModule } from "@fullcalendar/angular";
import { FormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { AddEventComponent } from "./scheduler/add-event/add-event.component";
import { AppMaterialModule } from "../app.material";
import { CalendarEventRest } from "./scheduler/add-event/ngrx/calendar-event-controller.service";
import { NgrxFormsModule } from "ngrx-forms";

const featureName = "navi-calendar";

@NgModule({
  imports: [
    NgrxFormsModule,
    // StoreModule.forFeature(featureName, schedulerRootReducer),
    AppMaterialModule,
    MatDialogModule,
    FormsModule,
    FullCalendarModule,
    CommonModule,
  ],
  declarations: [SchedulerComponent, AddEventComponent],
  exports: [SchedulerComponent, CommonModule],
  bootstrap: [SchedulerComponent],
  providers: [CalendarEventRest],
})
export class NavibudCalendarModule {}
