import { RepositoryBase, TableNames } from "./repositoryBase";
import { FilterPreset } from "@src/app/points/models/Settings/FilterPreset";

class PresetRepositoryClass extends RepositoryBase<FilterPreset> {
  constructor() {
    super(FilterPreset, TableNames.FilterPreset);
  }
}

export const PresetRepository = new PresetRepositoryClass();
