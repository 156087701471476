import { Injectable } from "@angular/core";
import { NaviPointsListService } from "../../navigation/services/navi-points-list.service";
import { MapInvestment } from "../../models/investment";
import { GeolocationRepository } from "../../dataAccess/geolocationRepository";
import State from "@src/app/State/appState";
import { Functions } from "../../services/common/functions";
import MapOptions = google.maps.MapOptions;
import { take } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { INaviStore } from "@src/app/State/RootReducer";
import { userActions } from "@src/app/State/user-slice";

@Injectable()
export class MapService {
  map: google.maps.Map;
  editorMode = false;
  directionsService: google.maps.DirectionsService;
  routeLength: number;
  routeDetails: Array<any> = [];
  ghostWriterInterval: NodeJS.Timer;
  isShowingGhost = false;
  state = State.mapState.value;
  private flightPath: google.maps.Polyline;
  private directionsRenderer: google.maps.DirectionsRenderer;
  private renderOption: google.maps.DirectionsRendererOptions = {
    polylineOptions: {
      strokeColor: "red",
    },
    suppressMarkers: true,
  };

  constructor(
    public naviPointsListService: NaviPointsListService,
    private store: Store<INaviStore>
  ) {
    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer(
      this.renderOption
    );

    this.store
      .select((x) => x.user.position)
      .subscribe((newPosition) => {
        this.updateUserMarker(newPosition);
        if (State.mapState.value.isFollowingUser.value) {
          this.mapFollowUser(newPosition);
        }
        if (this.isShowingGhost) {
          this.writeUserLocationPath();
        }
      });
  }

  clearDirections() {
    this.directionsRenderer.setMap(null);
    this.naviPointsListService.reset();
  }

  async CenterToUser(zoom = 15) {
    this.map.setZoom(zoom);
    const userPosition = await this.store
      .select((x) => x.user.position)
      .pipe(take(1))
      .toPromise();
    this.mapFollowUser(userPosition);
    State.mapState.value.isFollowingUser.next(true);
  }

  mapFollowUser(position: GeolocationPosition) {
    if (!this.map) {
      return;
    }
    this.map.setCenter(Functions.toGoogleMapsLatLng(position));
  }

  public async initializeMap(gmapElement: any) {
    await this.store
      .select((x) => x.user.position)
      .pipe(take(1))
      .toPromise();
    const userPosition = await this.store
      .select((x) => x.user.position)
      .pipe(take(1))
      .toPromise();
    const mapPosition =
      Functions.toGoogleMapsLatLng(userPosition) ??
      State.mapState.value.mapCenter;
    const mapProperties: MapOptions = {
      streetViewControl: false,
      center: mapPosition,
      zoom: 13,
      mapTypeId: google.maps.MapTypeId.HYBRID,
      gestureHandling: "greedy",
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP,
      },
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP,
      }
    };

    this.map = State.mapState.value.mainWindowMap = new google.maps.Map(
      gmapElement.nativeElement,
      mapProperties
    );

    this.directionsRenderer.setMap(this.map);
  }

  showRouteEditor(): void {
    this.editorMode = !this.editorMode;
  }

  async handleShowRoute() {
    const data = State.mapState.value.navigationList.value;
    const userPosition = await this.store
      .select((x) => x.user.position)
      .pipe(take(1))
      .toPromise();

    const request: google.maps.DirectionsRequest = {
      origin: Functions.toGoogleMapsLatLng(userPosition),
      destination: MapInvestment.toGoogleMapsLatLng(data[data.length - 1]),
      travelMode: google.maps.TravelMode["DRIVING"],
      waypoints: data.slice(0, -1).map((x) => {
        return {
          location: MapInvestment.toGoogleMapsLatLng(x),
          stopover: true,
        };
      }),
    };

    this.directionsService.route(request, (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        this.directionsRenderer.setDirections(result);
        // this.state.markers.value.forEach(marker => {
        //   if (marker.investment.seqNum == undefined || marker.investment.seqNum === '') {
        //     marker.marker.setMap(null);
        //   }
        //   else {
        //     let oldInvestment = marker.investment;
        //     for (let i = 0; i < data.length; i++) {
        //       if (data[i].identifier === oldInvestment.identifier) {
        //         marker.investment.seqNum = this.naviPointsListService.getSign(i);
        //         marker.marker.setIcon(this.naviPointsListService.getNaviMarkerIcon(marker.investment.seqNum));
        //         break;
        //       }
        //     }
        //   }
        // })

        // some Route metadata calculation
        this.routeLength = 0;
        result.routes.forEach((route) => {
          route.legs.forEach((leg) => {
            this.routeLength = this.routeLength + leg.distance.value;
            leg.steps.forEach((step) => {
              const seqNum: number = this.routeDetails.length + 1;
              const elem: any = {
                distance: step.distance.text,
                description: step.instructions,
                seqNum: seqNum,
              };
              this.routeDetails = [...this.routeDetails, elem];
            });
          });
        });
        this.routeLength = this.routeLength / 1000;
      }
    });
  }

  handleChangePosition(data: any) {
    this.naviPointsListService.handleChangePosition(data);
  }

  handleRemoveItem(data: any) {
    // this.state.markers.value.forEach(marker => {
    //   if (!(marker.investment.seqNum == undefined || marker.investment.seqNum === '')
    //     && marker.investment.identifier === data.identifier) {
    //     let image = {
    //       url: "/assets/markers/" + marker.investment.sellStatus + "/" + marker.investment.buildingStatus + ".svg",
    //       scaledSize: new google.maps.Size(50, 50),
    //     };
    //     marker.marker.setIcon(image);
    //   }
    // })
  }

  handleChooseDifferentNavi(data: any) {
    // State.mapState.value.markers.value.forEach(marker => {
    //   if (marker.investment.seqNum == undefined || marker.investment.seqNum === '') {
    //     marker.marker.setMap(this.map);
    //   }
    // })
    // this.naviPointsListService.resetAlphabet();
    // if (this.directionsRenderer != null) {
    //   this.directionsRenderer.setMap(null);
    //   this.directionsRenderer = null;
    // }
    // this.directionsRenderer = new google.maps.DirectionsRenderer(this.renderOption);
    // this.directionsRenderer.setMap(this.map);
  }

  clearNaviFlag() {
    this.editorMode = false;
  }

  switchUserPath(): void {
    this.isShowingGhost = !this.isShowingGhost;
    if (this.isShowingGhost) {
      this.writeUserLocationPath();
    } else {
      this.clearUserLocationPath();
    }
  }

  writeUserLocationPath() {
    const currentDate = new Date();
    GeolocationRepository.search((x) =>
      this.isSameDay(new Date((x.date as unknown) as string), currentDate)
    ) // Indexed db parses date to String type
      .then((st) => {
        const googleMapPoints = st.map(
          (x) => new google.maps.LatLng(x.lat, x.long)
        );
        this.clearUserLocationPath();
        this.flightPath = this.toGoogleMapPolyLine(googleMapPoints);
        this.flightPath.setMap(this.map);
      });
  }

  clearUserLocationPath() {
    if (this.flightPath) {
      this.flightPath.setMap(null);
    }
  }

  private updateUserMarker(position: GeolocationPosition) {
    if (position && State.mapState.value.isFollowingUser) {
      return;
    }
    State.mapState.value.userMarker.value?.setPosition(
      Functions.toGoogleMapsLatLng(position)
    );
  }

  private toGoogleMapPolyLine(points: Array<google.maps.LatLng>) {
    return new google.maps.Polyline({
      path: points,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  }

  private isSameDay(d1: Date, d2: Date) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }
}
