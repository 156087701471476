import { Routes } from "@angular/router";
import { LoginComponent } from "./auth/containers/login/login.component";
import { RegisterComponent } from "./auth/containers/register/register.component";
import { AuthGuard } from "./auth/guards/auth.guard";
import { HomeComponent } from "./core/containers/home/home.component";
import { PageNotFoundComponent } from "./core/containers/page-not-found/page-not-found.component";
import { MainLayoutComponent } from "./core/containers/main-layout/main-layout.component";
import { PendingChangesGuard } from "./points/services/pending-changes.service";
import { UserDataComponent } from "./core/containers/user/user-data/user-data.component";
import { ForgotPasswordComponent } from "./auth/containers/forgotPassword/forgot-password.component";
import { ResetPasswordComponent } from "./auth/containers/resetPassword/reset-password.component";
import { ConfirmEmailComponent } from "./core/containers/confirm-email/confirm-email.component";
import { NewPointComponent } from "./points/features/new-point/new-point.component";

export const appRoutes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "newPoint", component: NewPointComponent },
  { path: "register", component: RegisterComponent },
  { path: "forgotPassword", component: ForgotPasswordComponent },
  { path: "resetPassword", component: ResetPasswordComponent },
  { path: "confirm-email", component: ConfirmEmailComponent },
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
    children: [
      { path: "", redirectTo: "home", pathMatch: "full" },
      { path: "home", component: HomeComponent },
      { path: "user/data", component: UserDataComponent },
      {
        path: "points",
        loadChildren: () =>
          import("./points/points.module").then((m) => m.PointsModule),
      },
    ],
  },
  { path: "**", component: PageNotFoundComponent },
];
