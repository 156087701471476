import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Offer } from "@src/app/points/windows/info-tabs/offer/models/offer";

export interface IOfferState {
  offers: Offer[];
}

const initState: IOfferState = {
  offers: [],
};

const slice = createSlice({
  name: "OfferReducer",
  initialState: initState,
  reducers: {
    setOffers: (state, p: PayloadAction<Offer[]>) => {
      state.offers = p.payload;
      return state;
    },
    updateOffer: (state, p: PayloadAction<Offer>) => {
      const newOffers = state.offers.filter((x) => x.feId != p.payload.feId);
      newOffers.push(p.payload);
      state.offers = newOffers;
      return state;
    },
    offerAddedToDexie: (state, p: PayloadAction<Offer>) => {
      state.offers = [...state.offers, p.payload];
    },
    deleteOffer: (state, p: PayloadAction<{ feId: number }>) => {
      state.offers = state.offers.filter((x) => x.feId !== p.payload.feId);
    },
  },
});

export const OfferReducer = slice.reducer;
export const offerActions = slice.actions;
