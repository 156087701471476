import { Component, OnInit } from "@angular/core";
import { LayoutService } from "../../services/layout.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { UserLocationService } from "@src/app/points/services/user-location.service";
import { UserService } from "../../../auth/services/user.service";
import { MapService } from "@src/app/points/containers/map/map.service";
import { busyIndicatorService } from "@src/app/auth/redux/busyIndicatorState";
import {Log} from "@src/utils/log";
import {AuthService} from "@src/app/auth/services/auth.service";

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"],
})
export class MainLayoutComponent implements OnInit {
  constructor(
    private userLocationService: UserLocationService,
    private layoutService: LayoutService,
    private authService: AuthService,
    private ngxService: NgxUiLoaderService,
    private userService: UserService,
    public mapService: MapService
  ) {}
  ngOnInit(): void {
    this.track();
    busyIndicatorService.state.subscribe((state) => {
      if (state === null) return;
      const loaderId = "map-loader";
      if (state.isBusy) {
        Log.debug("startBackgroundLoader");
        this.ngxService.startBackgroundLoader(loaderId);
      } else {
        Log.debug("stopBackgroundLoader");
        this.ngxService.stopBackgroundLoader(loaderId);
      }
    });
  }

  toggleSidenav() {
    this.layoutService.toggleSidenav();
  }

  closeSidenav() {
    this.layoutService.closeSidenav();
  }

  get sidenavOpened(): boolean {
    return this.layoutService.isSidenavOpened;
  }

  track(): void {
    if (this.userLocationService.gotGeolocationPermission()) {
      this.userLocationService.startWatching();
    }
  }
  get login(): string {
    return this.userService.user.login;
  }

  async logout() {
    await this.authService.logout();
  }

  center() {
    this.mapService.CenterToUser();
  }
}
