import { createAction, createReducer, on } from "@ngrx/store";

export interface IAuthState {
  userName: string;
  token: string;
  userId: string;
}

const initState: IAuthState = {
  token: "",
  userId: "",
  userName: "",
};

export const authLoadLocalToken = createAction("[Login Page] Authenticate");

export const authReducer = createReducer(
  initState,
  on(authLoadLocalToken, (state) => {
    const token = localStorage.getItem("authToken");
    const parsedToken = parseAccessToken(token);

    return {
      token: token,
      userName: localStorage.getItem("user"),
      userId: parsedToken.UserId,
    };
  })
);

function parseAccessToken(token: string): { UserId: "string" } {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
