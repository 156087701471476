import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { CalendarOptions } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Calendar } from "@fullcalendar/core";
import bootstrap5Plugin from '@fullcalendar/bootstrap5';

import State from "@src/app/State/appState";
import { OnDestroyBase } from "@src/app/common/OnDestroyBase";
import { CalendarEvent } from "@src/app/points/models/event";
import { CalendarRepository } from "@src/app/points/dataAccess/calendar-repository.service";
import { MapInvestment } from "@src/app/points/models/investment";
import {
  AddEventComponent,
  IAddEventComponentData,
} from "@src/app/navibud-calendar/scheduler/add-event/add-event.component";
import { Store } from "@ngrx/store";
import { INaviStore } from "@src/app/State/RootReducer";
import { setCalendarEvents } from "@src/app/auth/redux/calendarState";
import { SyncStateEnum } from "@src/app/points/dataAccess/sync/ISynchronizable";
import { SyncService } from "@src/app/points/dataAccess/sync/sync.service";
import _ from "lodash";
import { SyncInvestmentServiceInstance } from "@src/app/points/dataAccess/sync/sync-investment.service";

@Component({
  selector: "app-scheduler",
  templateUrl: "./scheduler.component.html",
  styleUrls: ["./scheduler.component.scss"],
})
export class SchedulerComponent
  extends OnDestroyBase
  implements AfterViewInit, OnChanges, OnInit {
  @ViewChild("calendar") calendarComponent: FullCalendarComponent;

  constructor(
    public dialog: MatDialog,
    private store: Store<INaviStore>,
    private syncService: SyncService
  ) {
    super();
    this.plugins = [dayGridPlugin];
    this.selectedInvestment = State.mapState.value.selectedInvestment.value;
  }

  @Input() events: CalendarEvent[];
  @Input() isFilteredByCurrentInvestment: boolean;

  selectedInvestment: MapInvestment;
  @ViewChild("fullcalendar") fullcalendar: FullCalendarComponent;
  // plugins: PluginDef[];
  calendarApi: Calendar;
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, bootstrap5Plugin],
    themeSystem: 'bootstrap5',
    initialView: "dayGridMonth",
    buttonText: {
      today: "Dzisiaj",
      month: "Mies.",
      week: "Tydz.",
      day: "Dzień",
      list: "Lista",
    },
    buttonIcons: {
      prev: 'arrow-left',
      next: 'arrow-right',
    },
    locale: "pl",
    editable: true,
    dayMaxEventRows: false,
    eventClick: (event) => this.eventClick(event),
    contentHeight: "auto",
    titleFormat: { year: 'numeric', month: 'long' },

    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: "dayGridMonth dayGridWeek dayGridDay",

    },
  };

  // @ts-ignore
  private plugins: PluginDef[];

  ngOnInit() {
    this.store
      .select((x) => x.calendar)
      .subscribe((x) => {
        this.events = x.events.filter((e) => !e.isDeleted);
        this.renderEvents();
      });
    this.readUiEvents();
    this.subscriptions.push(
      State.mapState.value.selectedInvestment.subscribe(
        (x) => (this.selectedInvestment = x)
      )
    );
  }

  private readUiEvents() {
    if (this.isFilteredByCurrentInvestment) {
      CalendarRepository.dexieTable
        .filter((x) => !x.isDeleted)
        .toArray()
        .then((x) => {
          // null investmentId is global event
          const toDisplay = x.filter(
            (i) => i.investmentId === this.selectedInvestment.id
          );
          this.store.dispatch(setCalendarEvents(toDisplay));
        });
    } else {
      CalendarRepository.getAll().then((x) => {
        this.store.dispatch(setCalendarEvents(x));
      });
    }
  }

  ngOnChanges(): void {
    this.renderEvents();
    this.calendarApi?.render();
  }

  renderEvent(event: CalendarEvent) {
    this.calendarApi.addEvent(event as any);
    this.calendarApi.render();
  }

  async removeEvent(event: CalendarEvent) {
    const tmpCopy = Object.assign(new CalendarEvent(), event);
    tmpCopy.isDeleted = true;
    tmpCopy.syncState = SyncStateEnum.updatedByUser;
    await CalendarRepository.put(tmpCopy);
    await this.readUiEvents();
  }

  async addEvent(date: Date = null) {
    if (this.selectedInvestment?.id === 0) {
      await SyncInvestmentServiceInstance.syncSelected();
    }

    const dialogRef = this.dialog.open(AddEventComponent, {
      data: this.getNewEventData(date),
      panelClass: "custom-mat-panel",
    });
    dialogRef.afterClosed().subscribe(async (result: CalendarEvent) => {
      if (!result) return;

      result.syncState = SyncStateEnum.userCreated;
      result.investmentId = this.selectedInvestment?.id;
      await this.saveEvent(result);
    });
  }

  private async saveEvent(result: CalendarEvent) {
    await CalendarRepository.dexieTable.add(result);
    await this.syncService.syncCalendarAsync(true);
    this.readUiEvents();
  }

  eventClick(detail) {
    CalendarRepository.dexieTable
      .get(
        detail.event !== undefined
          ? detail.event._def.extendedProps.feId
          : detail.feId
      )
      .then((x) => {
        const dialogRef = this.dialog.open(AddEventComponent, {
          panelClass: "custom-mat-panel",
          data: {
            event: x,
            isNew: false,
          },
        });

        dialogRef.afterClosed().subscribe(async (resultCopy: CalendarEvent) => {
          if (!resultCopy) {
            return;
          }

          if (this.selectedInvestment?.id)
            resultCopy.investmentId = this.selectedInvestment?.id;
          await this.updateEvent(resultCopy);

          const foundIndex = this.events.findIndex(
            (i) => i.id === resultCopy.id
          );
          this.events[foundIndex] = resultCopy;
          this.renderEvents();
        });
      });
  }

  async updateEvent(event: CalendarEvent) {
    await CalendarRepository.update(event.feId, event);
  }

  private getNewEventData(date: Date): IAddEventComponentData {
    const newEvent = new CalendarEvent();
    if (this.selectedInvestment)
      newEvent.counterparty = `${this.selectedInvestment.investorFirstName} ${this.selectedInvestment.investorLastName}`;
    if (date) {
      newEvent.start = new Date(date.setHours(9, 0, 0, 0))
        .toISOString()
        .slice(0, 16);
      newEvent.end = new Date(
        new Date(date.getTime() + 2 * 60 * 60 * 1000).setHours(11, 0, 0, 0)
      )
        .toISOString()
        .slice(0, 16);
    }
    return {
      event: newEvent,
      isNew: true,
    };
  }

  private renderEvents() {
    if (!this.calendarApi || !this.events?.length) {
      return;
    }
    this.calendarApi.removeAllEvents();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this.events = _.filter([...this.events], function (event) {
      return new Date(event.start) >= yesterday;
    });
    this.events = _.orderBy([...this.events], ["start"], ["desc"]);
    this.events.forEach((x) => {
      this.renderEvent(x);
    });
    this.calendarApi?.render();
  }

  ngAfterViewInit(): void {
    this.calendarApi = this.calendarComponent.getApi();
  }

  getDay(date) {
    const day = new Date(date);

    return day.getDate();
  }

  getDayName(date) {
    const dayNames = ["NIEDZ", "PON.", "WT.", "ŚR.", "CZW.", "PT.", "SOB"];
    const d = new Date(date);
    const dayName = dayNames[d.getDay()];

    return dayName;
  }

  getMonthName(date) {
    const monthNames = [
      "STY",
      "LUT",
      "MAR",
      "KWI",
      "MAJ",
      "CZE",
      "LIP",
      "SIE",
      "WRZ",
      "PAŹ",
      "LIS",
      "GRU",
    ];

    const m = new Date(date);
    const monthName = monthNames[m.getMonth()];

    return monthName;
  }

  getRange(event: CalendarEvent) {
    const startDate = new Date(event.start);
    const eventStartDay = startDate.getDate();
    const endDate = new Date(event.end);
    const eventEndDay = endDate.getDate();
    let range = 0;

    range = eventEndDay - eventStartDay;

    return range;
  }

  getNextDay(date, dayStep) {
    const day = new Date(date);
    const nextDay = new Date(day);
    nextDay.setDate(day.getDate() + dayStep);

    return nextDay;
  }

  getTime(start, end) {
    const startTime = new Date(start).toLocaleTimeString().slice(0, 5);
    const endTime = new Date(end).toLocaleTimeString().slice(0, 5);
    let result = "";

    if (startTime === endTime) {
      result = `${startTime}`;
    } else {
      result = `${startTime} - ${endTime}`;
    }

    return result;
  }

  getRangeImage() {
    return `/assets/icons/date_range.svg`;
  }

  getCounterParty(event) {
    if (event.counterparty !== "") {
      return true;
    }
    return false;
  }
}
