<div class="user-password-content">
  <div class="user-password-header">
    <h3>Edycja hasła</h3>
    <button mat-icon-button class="image-close-button" aria-label="Zamknij okno" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form class="password-form">
    <div>
      <label for="oldPassword">Stare hasło</label>
      <input type="password" id="oldPassword" [(ngModel)]="oldPassword" name="oldPassword" required />
      <div *ngIf="!oldPassword && passwordFormSubmitted" class="error-msg">
        Pole jest wymagane
      </div>
    </div>
    <div>
      <label for="newPassword">Nowe hasło</label>
      <input type="password" id="newPassword" [(ngModel)]="newPassword" name="newPassword" required />
      <div *ngIf="!newPassword && passwordFormSubmitted" class="error-msg">
        Pole jest wymagane
      </div>
    </div>
    <div>
      <label for="confirmPassword">Potwierdź nowe hasło</label>
      <input type="password" id="confirmPassword" [(ngModel)]="confirmPassword" name="confirmPassword" required />
      <div *ngIf="!confirmPassword && passwordFormSubmitted" class="error-msg">
        Pole jest wymagane
      </div>
    </div>
    <button mat-button type="submit" (click)="changePassword()">Zmień hasło</button>
  </form>
</div>