import { Component, OnInit } from "@angular/core";
import Chart from "chart.js/auto";
import { InvestmentRepository } from "@src/app/points/dataAccess/investmentRepository";
import _ from "lodash";
import { MapInvestment } from "@src/app/points/models/investment";

@Component({
  selector: "app-line-chart",
  templateUrl: "./line-chart.component.html",
  styleUrls: ["./line-chart.component.scss"],
})
export class LineChartComponent implements OnInit {
  investments: MapInvestment[];
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  constructor() {}

  public myChart: Chart;
  async ngOnInit(): Promise<void> {
    this.investments = await InvestmentRepository.getAll();

    this.myChart = new Chart("line-chart", {
      type: "line",
      data: {
        labels: this.lastDays(7),
        datasets: [
          {
            label: "Odwiedzone inwestycje",
            data: [20, 18, 33, 45, 55, 28, 76],
            fill: true,
            borderColor: "rgba(0, 172, 193, 1)",
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: "Historia odwiedzonych inwestycji",
            align: "start",
            font: {
              size: 18,
            },
          },
        },
        responsive: true,
      },
    });
  }

  lastDaysClick(days) {
    const lastDays = this.lastDays(days);
    const data = this.getRandomInt(days);
    this.myChart.data.labels = [];
    this.myChart.data.datasets[0].data = [];

    lastDays.map((x) => this.myChart.data.labels.push(x));
    data.map((x) => this.myChart.data.datasets[0].data.push(x));

    this.myChart.update();
  }

  lastMonthsClick(months) {
    const lastMonths = this.lastMonths(months);
    const data = this.getRandomInt(months);
    this.myChart.data.labels = [];
    this.myChart.data.datasets[0].data = [];

    lastMonths.map((x) => this.myChart.data.labels.push(x));
    data.map((x) => this.myChart.data.datasets[0].data.push(x));

    this.myChart.update();
  }

  getVisitedInvestmentsCount() {
    var count = _.countBy(this.investments, function (investment) {
      return investment.isVisited;
    });

    return count.true;
  }

  lastDays(count) {
    var result = [];
    for (var i = 0; i < count; i++) {
      var d = new Date();
      d.setDate(d.getDate() - i);
      result.unshift(this.formatDate(d));
    }

    return result;
  }

  lastMonths(count) {
    let result = [];
    const today = new Date();
    let d;
    let month;

    for (var i = count; i > 0; i -= 1) {
      d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      month = this.monthNames[d.getMonth()];
      result.push(month);
    }

    return result;
  }

  formatDate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    date = dd + "-" + mm + "-" + yyyy;
    return date;
  }

  getRandomInt(count) {
    let result = [];

    for (var i = 0; i < count; i++) {
      let max = Math.floor(100);
      result.push(Math.floor(Math.random() * (max - 0)) + 0);
    }

    return result;
  }
}
