import {Timestamp} from 'google-protobuf/google/protobuf/timestamp_pb';
import {IMapInvestment, MapInvestment} from "@src/app/points/models/investment";
import State from "@src/app/State/appState";
import {InvestmentRepository} from "@src/app/points/dataAccess/investmentRepository";
import {PaginationService} from "@src/app/points/dataAccess/paginationService";
import {InvestmentEndpoints} from "@src/app/endPoints/investmentEndpoints";
import {SyncStateEnum} from "@src/app/points/dataAccess/sync/ISynchronizable";
import {processFetched, SyncGenericWithStream} from "@src/app/points/dataAccess/sync/GenericSync";
import {busyIndicatorService} from "@src/app/auth/redux/busyIndicatorState";
import {syncStateService} from "@src/app/State/lastSyncState";
import {GET, getAuthHeaders} from "@src/utils/HTTP";
import {Investments_GrpcClient} from "@src/grpc/InvestmentsServiceClientPb";
import {investments_reply_grpc, investments_request_grpc} from "@src/grpc/Investments_pb";
import {environment} from "@src/environments/environment";
import {names} from "@src/utils/names";
import {defaultIfEmptyOrNull} from "@src/app/points/services/common/functions";
import {Log} from "@src/utils/log";

const distance = 50000;
function getQuery(): IRetrieveAllInvestmentsRequest {
  const userPosition = State.user.value.position.coords;
  const lastSyncDate = syncStateService.state.value.investments.lastSyncDate;

  return {
    Distance: distance,
    Longitude: userPosition.longitude,
    Latitude: userPosition.latitude,
    LastSyncDate: lastSyncDate,
  };
}
class SyncInvestmentService {
  constructor() {}

  // outDated takes much time
  async createInvestment(investment: MapInvestment) {
    investment.syncState = SyncStateEnum.newInUiTouched;
    await InvestmentRepository.put(investment);
    await this._syncNearest();
  }

  async createNewInvestments(investments: MapInvestment[]): Promise<MapInvestment[]> {
    const paginationService = new PaginationService(MapInvestment);
    const investmentsFromServer = await paginationService.createItemsInServer(
      InvestmentEndpoints.createRange,
      investments
    );
    const result: MapInvestment[] = [];
    for (const x of investmentsFromServer) {
      delete x.feId;
      const newInvestment = Object.assign(new MapInvestment(), {
        ...x
      }) as MapInvestment;
      await InvestmentRepository.add(newInvestment);
      result.push(newInvestment);
    }

    return result;
  }

  areaGrpc(): Promise<MapInvestment[]> {
    const userPosition = State.user.value.position.coords;

    return new Promise((resolve, reject) => {
      const items: MapInvestment[] = [];
      const investmentService = new Investments_GrpcClient(environment.api.baseUri, null, null);

      const req = new investments_request_grpc();
      req.setLongitude(userPosition.longitude);
      req.setLatitude(userPosition.latitude);
      req.setLastSyncDate(getLastSyncTimeStamp());
      const call = investmentService.allArea_Grpc(req, getAuthHeaders());

      call.on('data', data => {
        const grpc_investment = investments_reply_grpc.toObject(false, data);
        const investment = MapToInvestment(grpc_investment);
        items.push(investment);
      });

      call.on("error", (error) => {
        reject([]); // Reject the promise on error
      });

      call.on("end", () => {
        resolve(items); // Resolve the promise when the call ends
      });
    });
  }

  exceptAreaGrpc(): Promise<MapInvestment[]> {
    const userPosition = State.user.value.position.coords;

    return new Promise((resolve, reject) => {
      const items: MapInvestment[] = [];
      const investmentService = new Investments_GrpcClient(environment.api.baseUri, null, null);
      const req = new investments_request_grpc();
      req.setLongitude(userPosition.longitude);
      req.setLatitude(userPosition.latitude);
      req.setLastSyncDate(getLastSyncTimeStamp());
      const call = investmentService.allExceptArea_Grpc(req, getAuthHeaders());

      call.on('data', data => {
        try {
          const grpc_investment = investments_reply_grpc.toObject(false, data);
          const investment = MapToInvestment(grpc_investment);
          items.push(investment);
        } catch (e) {
          Log.Error(`Unable to load exceptAreaGrpc `, data, e);
        }
      });

      call.on("error", (error) => {
        Log.Error(error);
        reject([]); // Reject the promise on error
      });

      call.on("end", () => {
        Log.debug("exceptAreaGrpc succeed");
        resolve(items); // Resolve the promise when the call ends
      });
    });
  }


  async SyncNearestAsync() {
    await this._syncNearest();
  }

  private async _syncNearest() {
    if (new Date(getQuery()?.LastSyncDate).isSameDay(new Date())) return;

    return SyncGenericWithStream({
      type: MapInvestment,
      repository: InvestmentRepository,
      endpoint: InvestmentEndpoints,
      fetchAllMethod: this.areaGrpc,
    });
  }

  async syncSelected() {
    const key = "syncing selected";
    busyIndicatorService.setBusyActionWithKey(key);
    const selectedInvestment = State.mapState.value.selectedInvestment.value;
    const paginationService = new PaginationService(MapInvestment);
    const [result] =
      selectedInvestment.syncState === SyncStateEnum.newInUiTouched
        ? await paginationService.createItemsInServer(
            InvestmentEndpoints.createRange,
            [selectedInvestment]
          )
        : await paginationService.updateItemsInServer(
            InvestmentEndpoints.updateRange,
            [selectedInvestment]
          );
    await InvestmentRepository.put(result);

    const BreakException = {};
    try {
      State.data.investment.items.value.forEach((x) => {
        if (x.feId === result.feId) {
          Object.assign(x, result);
          throw BreakException; // only way to simulate find method
        }
      });
    } catch (e) {}
    State.mapState.value.selectedInvestment.next(result);
    busyIndicatorService.setFreeActionWithKey(key);
  }

  async syncSelectedIfNoId() {
    const selectedInvestment = State.mapState.value.selectedInvestment.value;
    if (!selectedInvestment.id) {
      await this.syncSelected();
    }
  }

  async SyncAllExceptNearest() {
    if (new Date(getQuery()?.LastSyncDate).isSameDay(new Date())) return;

    Log.debug(`SyncAllExceptNearest starting to fetch investments`);
    const mapInvestments = await this.exceptAreaGrpc();

    Log.debug(`SyncAllExceptNearest fetched ${mapInvestments.length} investments`);
    await processFetched(InvestmentRepository, mapInvestments);
    Log.debug(`SyncAllExceptNearest processed all investments`);
  }
}

async function fetchInvestments<T>(
  queryObj: IRetrieveAllInvestmentsRequest,
  endpoint: string
): Promise<T> {
  const query = Object.keys(queryObj)
    .filter((key) => queryObj[key])
    .reduce((acc, key) => {
      acc[key] = queryObj[key];
      return acc;
    }, {});
  const queryString = new URLSearchParams(query).toString();

  const url = `${endpoint}?${queryString}`;
  return await GET<T>(url);
}

interface IRetrieveAllInvestmentsRequest {
  Latitude: number;
  Longitude: number;
  LastSyncDate: string;
  Distance: number;
}

export const SyncInvestmentServiceInstance = new SyncInvestmentService();

function MapToInvestment(grpc_investment: investments_reply_grpc.AsObject): MapInvestment {
  const poco: IMapInvestment = {
    ...grpc_investment,
    nextContactDate: getDate(grpc_investment.nextContactDate?.seconds),
    permissionDate: getDate(grpc_investment.permissionDate?.seconds),
    lastUpdate: getDate(grpc_investment.lastUpdate?.seconds),
    importDate: grpc_investment.importDate?.seconds,
    lat: Number.parseFloat(grpc_investment.lat),
    lng: Number.parseFloat(grpc_investment.lng),
    notes: [],
    calendarEvents: [],
    buildingStatus: grpc_investment.buildingStatus?.value ?? "nieznany",
    city: grpc_investment.city?.value,
    commune: grpc_investment.commune?.value,
    county: grpc_investment.county?.value,
    description: grpc_investment.description?.value,
    fullAddress: grpc_investment.fullAddress?.value,
    identifier: grpc_investment.identifier?.value,
    intentType: grpc_investment.intentType?.value,
    investorCity: grpc_investment.investorCity?.value,
    investorEmail: grpc_investment.investorEmail?.value,
    investorFirstName: grpc_investment.investorFirstName?.value,
    investorLastName: grpc_investment.investorLastName?.value,
    investorPhone: grpc_investment.investorPhone?.value,
    investorPostcode: grpc_investment.investorPostcode?.value,
    investorStreetName: grpc_investment.investorStreetName?.value,
    investorType: grpc_investment.investorType?.value,
    nip: grpc_investment.nip?.value,
    permissionNumber: grpc_investment.permissionNumber?.value,
    plot: grpc_investment.plot?.value,
    plotNumber: grpc_investment.plotNumber?.value,
    postcode: grpc_investment.postcode?.value,
    precinct: grpc_investment.precinct?.value,
    products: grpc_investment.products?.value,
    registrationUnit: grpc_investment.registrationUnit?.value,
    regon: grpc_investment.regon?.value,
    resignationReason: defaultIfEmptyOrNull(grpc_investment.resignationReason?.value, names.unsupportedField),
    sellStatus: grpc_investment.sellStatus?.value,
    state: grpc_investment.state?.value,
    streetName: grpc_investment.streetName?.value,
    streetNumber: grpc_investment.streetNumber?.value,
    terc: grpc_investment.terc?.value,
    userId: grpc_investment.userId?.value,
    community: grpc_investment.community?.value,
    district: grpc_investment.district?.value,
    isFenced: grpc_investment.isFenced?.value,
    mapNo: grpc_investment.mapNo?.value,
  } as IMapInvestment;

  const result = MapInvestment.new(poco);
  return result;
}

function getLastSyncTimeStamp() {
  const timestamp = new Timestamp();
  timestamp.fromDate(new Date(syncStateService.state.value.investments.lastSyncDate));

  return timestamp;
}

function getDate(dateInSeconds: number | undefined) {
  const date = new Date(dateInSeconds * 1000);
  if (date.getFullYear() < 2000)
    return undefined;

  return date.toNoMillisecondIsoString();
}
