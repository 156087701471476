import _ from "lodash";
import { BehaviorSubject } from "rxjs";
import { UserCreationStatus } from "../statuses/userCreationStatus";
import { SellChancesStatus } from "../statuses/sellChancesStatus";
import { DevelopmentStatus } from "../statuses/developmentStatus";
import { OfferStatus } from "../statuses/offerStatus";

export class InvestmentFilters {
  quickSearch = "";
  constructionStates: Array<string> = [];
  sellStates: Array<string> = [];
  createdByUserState: string = UserCreationStatus.None;
  offerStatus: string = OfferStatus.None;
  sellChances: SellChancesStatus[] = [SellChancesStatus.None];
  distance = 13;
  updateRange: DateRange = new DateRange();
  permissionRange: DateRange = new DateRange();
  calendarEventRange: DateRange = new DateRange();
  product: string;
  nextContactDateRange: DateRange = new DateRange();
  developmentState: DevelopmentStatus = DevelopmentStatus.None;
}

export class InvestmentFilterSetting {
  name: string;
  filters: InvestmentFilters = new InvestmentFilters();
  isDefault: boolean;

  static CreateNew(name: string = "Nowe") {
    const result = new InvestmentFilterSetting();
    result.name = name;
    return result;
  }
}

export class DateRange {
  isEnabled: false;
  // startDateInMs: number = moment("1980-04-20").valueOf();
  start: Date = null;

  // start = moment("1980-04-20").valueOf();
  // endDateInMs: number = moment().valueOf();
  end: Date = null;
}

export interface IFiltersState {
  settings: InvestmentFilterSetting[];
  selectedSetting: InvestmentFilterSetting;
}

const initState: IFiltersState = {
  settings: [],
  selectedSetting: null,
};

export const filterState = new BehaviorSubject(initState);