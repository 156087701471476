import { FilterStatusOption } from "./filterStatusOption";

export const sellStatuses: FilterStatusOption[] = [
    { displayName: "Nieznany", backendName: "unknown" },
    { displayName: "Oferta Wysłana", backendName: "oferta_wyslana" },
    { displayName: "Podpisano", backendName: "podpisano" },
    { displayName: "Rozmowa Telefoniczna", backendName: "rozmowa_telefoniczna" },
    { displayName: "Rozmowa z Klientem", backendName: "rozmowa_z_klientem" },
    { displayName: "Ulotka", backendName: "ulotka" },
    { displayName: "Pomiar", backendName: "pomiar" },
    { displayName: "Nie Podpisano", backendName: "nie_podpisano" },
    { displayName: "Negocjacje", backendName: "negocjacje" }
  ];