import {
  ISynchronizable,
  SyncStateEnum,
} from "../../dataAccess/sync/ISynchronizable";
import { InvestmentFilterSetting } from "../../windows/filter/state/filter-slice";

export class FilterPreset implements ISynchronizable {
  jsonData: InvestmentFilterSetting;

  //base
  userId?: string;
  id?: number;
  feId?: number;
  lastUpdate?: string;
  syncState: SyncStateEnum;
  isDeleted?: boolean;

  static createNew(): FilterPreset {
    return {
      jsonData: { ...InvestmentFilterSetting.CreateNew(), isDefault: true },
      syncState: SyncStateEnum.newInUi,
    };
  }
}
