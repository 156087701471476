import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { apiUrl, baseUrl } from "../../endPoints/endPoints";
import { INaviStore } from "../../State/RootReducer";
import { authLoadLocalToken } from "../redux/authState";
import {sessionService} from "@src/app/core/services/session.service";
import {storageService} from "@src/app/services/storageService";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private store: Store<INaviStore>,
  ) {}

  async logout() {
    await storageService.cleanup();
    sessionService.logoutEndOfSession();
  }

  isLoggedIn(): Observable<boolean> {
    if (this.verifyLogin()) {
      this.store.dispatch(authLoadLocalToken());
    }
    return of(this.verifyLogin());
  }

  login(username: string, password: string): any {
    const url = `${apiUrl()}/ApplicationUser/Login`;
    const data: any = {
      userName: username,
      password: password,
    };
    return this.httpClient.post(url, data);
  }

  register(
    email: string,
    password: string,
    fullname: string,
    username: string
  ): any {
    //const url = `${apiUrl()}/ApplicationUser/Register`;
    const url = `https://localhost:5001/users/register`;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      params: new HttpParams(),
    };
    const data: any = {
      userName: username,
      email: email,
      fullName: fullname,
      password: password,
    };
    return this.httpClient.post(url, data, httpOptions);
  }

  changePassword(
    username: string,
    currentPassword: string,
    newPassword: string
  ): Observable<any> {
    const url = `https://localhost:5001/users/changePassword`;
    const data = {
      userName: username,
      currentPassword: currentPassword,
      newPassword: newPassword,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.httpClient.post(url, data, httpOptions);
  }

  forgotPassword(email: string): Observable<any> {
    const url = `https://localhost:5001/users/forgotPassword`;
    const data = {
      email: email,
    };
    return this.httpClient.post(url, data);
  }

  resetPassword(
    email: string,
    token: string,
    newPassword: string
  ): Observable<any> {
    const url = `https://localhost:5001/users/resetPassword`;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    const data = {
      email: email,
      token: token,
      newPassword: newPassword,
    };
    return this.httpClient.post(url, data, httpOptions);
  }

  confirmEmail(email: string, token: string): Observable<any> {
    const url = `${baseUrl()}/users/confirm-email`;
    //const url = `https://localhost:5001/users/confirm-email`;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      params: {
        email: email,
        token: token
      }
    };
    return this.httpClient.get(url, httpOptions);
  }

  private verifyLogin(): boolean {
    const token = localStorage.getItem("authToken");
    return token !== null && token.length > 0;
  }
}
