import { IEntity } from "@src/app/points/dataAccess/repositoryBase";

export interface ISynchronizable extends IEntity {
  userId?: string;
  id?: number;
  feId?: number;
  lastUpdate?: string;
  syncState: SyncStateEnum;
  isDeleted?: boolean;
}

export enum SyncStateEnum {
  inSync,
  userCreated,
  updatedByUser,
  newInUi, // special state for investmentBase entity (SQL db),
  newInUiTouched, // special state for investmentBase entity (SQL db)
}
