import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { InvestmentEndpoints } from "@src/app/endPoints/investmentEndpoints";

@Injectable({
  providedIn: "root",
})
export class CounterPartyApiService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<string[]>(InvestmentEndpoints.CounterParties);
  }
}
