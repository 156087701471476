import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthGuard {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      map((result) => {
        if (!result) {
          this.router.navigate(["/login"]);
          return false;
        }
        return true;
      })
    );
  }
}
