import { CalendarEvent } from "@src/app/points/models/event";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ICalendarState {
  events: CalendarEvent[];
}

const initState: ICalendarState = {
  events: [],
};

const slice = createSlice({
  name: "CalendarReducer",
  initialState: initState,
  reducers: {
    setCalendarEvents: (state, p: PayloadAction<CalendarEvent[]>) => {
      state.events = p.payload;
      return state;
    },
  },
});

export const CalendarReducer = slice.reducer;
export const { setCalendarEvents } = slice.actions;
