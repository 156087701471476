import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import EndPoints from "../../endPoints/endPoints";
import { MapInvestment } from "../models/investment";
import { InvestmentRepository } from "../dataAccess/investmentRepository";
import { InvestmentEndpoints } from "@src/app/endPoints/investmentEndpoints";
import { SyncStateEnum } from "@src/app/points/dataAccess/sync/ISynchronizable";
import _ from "lodash";
import { MapQueryParserServiceService } from "@src/app/points/containers/map/mapQueryParserService/map-query-parser-service.service";
import { Router } from "@angular/router";
import { busyIndicatorService } from "@src/app/auth/redux/busyIndicatorState";
import { SyncInvestmentServiceInstance } from "@src/app/points/dataAccess/sync/sync-investment.service";

@Injectable({
  providedIn: "root",
})
export class InvestmentsService {
  constructor(private httpClient: HttpClient, private router: Router) {}

  async delete(investments: MapInvestment[]) {
    const busyKey = "delete investment Busy Key";
    busyIndicatorService.setBusyActionWithKey(busyKey);
    await MapQueryParserServiceService.setUrl(
      { infoWindowStatus: undefined },
      null,
      this.router
    );
    const toDelete = _.cloneDeep(investments);
    toDelete.forEach((x) => (x.isDeleted = true));
    const url = InvestmentEndpoints.updateRange;
    await this.httpClient.put(url, toDelete).toPromise();
    const idsToDelete = toDelete.map((x) => x.feId);
    await InvestmentRepository.dexieTable.bulkDelete(idsToDelete);
    await SyncInvestmentServiceInstance.SyncNearestAsync();
    busyIndicatorService.setFreeActionWithKey(busyKey);
  }

  async updateInvestmentAsync(investment: MapInvestment) {
    if (investment.syncState === SyncStateEnum.newInUi)
      investment.syncState = SyncStateEnum.newInUiTouched;
    if (investment.syncState === SyncStateEnum.inSync)
      investment.syncState = SyncStateEnum.updatedByUser;
    await InvestmentRepository.update(investment.feId, investment);
  }

  getById(id: number) {
    if (id === undefined) {
      return;
    }
    const url = `${EndPoints.InvestmentsWithImage}/${id}`;
    return this.httpClient.get(url);
  }

  uploadFile(file: File, investmentId: number): void {
    const key = "on file upload";
    busyIndicatorService.setBusy(key);
    const formData = new FormData();
    formData.append("files", file);

    this.httpClient
      .post<any>(EndPoints.UploadImage(investmentId), formData)
      .subscribe(
        (data) => {
          busyIndicatorService.setFree(key);
          console.log("success", data);
        },
        (error) => {
          busyIndicatorService.setFree(key);
          console.log("error", error);
        }
      );
  }

  createNew(investment: MapInvestment, callBack: () => void) {
    InvestmentRepository.add(investment).then((x) => {
      this.httpClient
        .post<MapInvestment>(InvestmentEndpoints.CreateSingle, investment)
        .subscribe((result) => {
          InvestmentRepository.dexieTable
            .delete(x)
            .then((deleted) =>
              InvestmentRepository.dexieTable.add(result).then(callBack)
            );
        });
    });
  }

  localSaveAll(data) {
    if (!data) {
      return;
    }
    InvestmentRepository.bulkPut(data);
  }

  localSynchronize(data) {
    if (!data) {
      return;
    }
    InvestmentRepository.put(data);
  }
}
