<div class="frame scheduler-scroll no-offer-overflow">
  <h2>Terminarz</h2>
  <div class="new-event-button">
    <button (click)="addEvent()">
      <mat-icon class="far fa-calendar-plus">add</mat-icon> Dodaj nowe
      wydarzenie
    </button>
  </div>
  <div class="scheduler">
    <div class="scheduler-calendar">
      <div class="text-center white-background mh-80p calendar-full">
        <full-calendar #calendar [options]="calendarOptions"></full-calendar>
      </div>
    </div>
    <div class="scheduler-events-container">
      <div class="scheduler-events-header">
        <h3>Wydarzenia:</h3>
      </div>
      <div class="scheduler-events">
        <div class="calendar-events-list" *ngFor="let event of events">
          <div class="event-row">
            <div class="event-day-number">{{ getDay(event.start) }}</div>
            <div class="event-day-description">
              {{ getMonthName(event.start) }}, {{ getDayName(event.start) }}
            </div>
            <div class="event-day-time">
              {{ getTime(event.start, event.end) }}
            </div>
            <div class="event-counterparty">
              <mat-icon
                *ngIf="getCounterParty(event)"
                title="{{ event.counterparty }}"
                >person</mat-icon
              >
              <mat-icon *ngIf="!getCounterParty(event)" title="Brak kontrahenta"
                >person_off</mat-icon
              >
            </div>
            <div class="event-title">
              {{ event.title }}
              <div class="event-description">
                {{ event.description }}
              </div>
            </div>
            <div id="openInvestmentButton" class="open-investment-enabled">
              <a
                *ngIf="event.investmentId !== undefined"
                title="Otwórz inwestycję"
                mat-button
                href="/points/map?investmentId={{
                  event.investmentId
                }}&infoWindowStatus=editable"
                target="_self"
              >
                <mat-icon>open_in_new</mat-icon>
              </a>
            </div>
            <button class="open-event-button" (click)="eventClick(event)">
              <mat-icon>edit_note</mat-icon>
            </button>
            <button class="remove-button" (click)="removeEvent(event)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <img
            class="icon-image range-image"
            *ngIf="getRange(event) !== 0"
            [src]="getRangeImage()"
          />
          <div class="event-row" *ngIf="getRange(event) !== 0">
            <div class="event-day-number">{{ getDay(event.end) }}</div>
            <div class="event-day-description">
              {{ getMonthName(event.end) }}, {{ getDayName(event.end) }}
            </div>
            <div class="event-day-time">
              {{ getTime(event.start, event.end) }}
            </div>
            <div class="event-counterparty"></div>
            <div class="event-title"></div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
  </div>
</div>
