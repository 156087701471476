<div class="add-event-header">
  <h3 mat-dialog-title *ngIf="isNew">Dodaj nowe wydarzenie</h3>
  <h3 mat-dialog-title *ngIf="!isNew">Edytuj wydarzenie</h3>
  <button mat-icon-button class="image-close-button" aria-label="Zamknij okno"  [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="dialog-modal">
  <form class="form-info">
    <div class="form-group">
      <mat-form-field class="full-width lowered">
        <input matInput class="big-input" name="title" type="text" placeholder="Nazwa" [(ngModel)]="event.title"
          autofocus />
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field class="full-width lowered">
        <mat-select [(ngModel)]="event.status" name="food">
          <mat-option *ngFor="let status of statuses" [value]="status">
            {{ status }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="isInfoWindowOpen" class="full-width investor-name">
        <mat-label>Nazwa inwestora:</mat-label><br />
        <p>{{ event.counterparty }}</p>
      </div>
    </div>
    <div class="form-group">
      <mat-form-field class="full-width lowered">
        <input matInput class="big-input" name="description" type="text" placeholder="Opis"
          [(ngModel)]="event.description" />
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field class="full-width lowered">
        <input matInput class="big-input" name="start" type="datetime-local" placeholder="Data rozpoczęcia"
          [(ngModel)]="event.start" />
      </mat-form-field>
      <mat-form-field class="full-width lowered">
        <input matInput class="big-input" name="end" type="datetime-local" placeholder="Data zakończenia"
          [(ngModel)]="event.end" />
      </mat-form-field>
    </div>
    <div class="action-buttons">
      <!-- <div class="button">
        <a href="/points/map?infoWindowStatus=editable&investmentId={{
            event.investmentId
          }}">Otworz inwestycje</a>
      </div> -->
      <button class="btn-primary" color="primary" (click)="closeDialog()">
        <mat-icon class="scaled-icon">save</mat-icon>
        <span class="button-text">Zapisz</span>
      </button>

    </div>
  </form>
</div>