import { BehaviorSubject } from "rxjs";
import { MapState } from "./mapState";
import { MapInvestment } from "../points/models/investment";
import { Injectable } from "@angular/core";
import { CalendarStore } from "./calendarStore";
import { IUserState } from "@src/app/State/user-slice";
import { Offer } from "@src/app/points/windows/info-tabs/offer/models/offer";
import { FilterPreset } from "@src/app/points/models/Settings/FilterPreset";
import { FilterPresetEntityService } from "@src/app/points/dataAccess/sync/PresetEntityService";
import {Note} from "@src/app/points/windows/info-tabs/notes/models/note";

@Injectable({
  providedIn: "root",
})
class StateClass {
  mapState = new BehaviorSubject(new MapState());
  user = new BehaviorSubject<IUserState>({
    isSignal: false,
    position: null,
    userId: undefined,
  });

  data = {
    offer: {
      selected: new BehaviorSubject<Offer>(null),
      items: new BehaviorSubject<Offer[]>([]),
    },
    investment: {
      items: new BehaviorSubject<Set<MapInvestment>>(new Set()),
      // selected: new BehaviorSubject<MapInvestment>(null),
    },
    notes: {
      items: new BehaviorSubject<Note[]>([]),
      // selected: new BehaviorSubject<MapInvestment>(null),
    },
    settings: {
      filterPreset: {
        items: FilterPresetEntityService.items,
      },
    },
  };

  constructor(public calendarStore: CalendarStore) {}
}

const State = new StateClass(null);
(window as any).State = State;
export default State;
