import {
  ISynchronizable,
  SyncStateEnum,
} from "@src/app/points/dataAccess/sync/ISynchronizable";
import { IRepositoryBase } from "@src/app/points/dataAccess/repositoryBase";
import { ISynchronizableUrl } from "@src/app/endPoints/endPoints";
import { PaginationService } from "@src/app/points/dataAccess/paginationService";
import { GET } from "@src/utils/HTTP";
import * as _ from "lodash";

export async function processFetched<T extends ISynchronizable>(
  repository: IRepositoryBase<T>,
  fetchedItems: T[]
) {
  if (!fetchedItems.length) return;

  const repositoryMap: { [key: string]: number } = (
    await repository.dexieTable.toArray()
  ).reduce((acc, next) => {
    acc[next.id] = next.feId;
    return acc;
  }, {});
  const toUpdate = [];
  const toCreate = [];

  fetchedItems.forEach((item) => {
    const value = repositoryMap[item.id];
    if (value) {
      item.feId = value;
      toUpdate.push(item);
      return;
    }

    delete item.feId;
    toCreate.push(item);
  });

  for (const x of _.chunk(toCreate, 800)) {
    await repository.dexieTable.bulkAdd(x);
  }

  for (const x of _.chunk(toUpdate, 800)) {
    await repository.dexieTable.bulkPut(x);
  }
}

export async function SyncGenericWithStream<T extends ISynchronizable>({
  type,
  repository,
  endpoint,
  comparisonFunction = (a: T, b: T) => a.feId === b.feId,
  fetchAllMethod,
  query,
  forceSync = false,
}: {
  type: new () => T;
  repository: IRepositoryBase<T>;
  endpoint: ISynchronizableUrl;
  comparisonFunction?: (a: T, b: T) => boolean;
  fetchAllMethod?: () => Promise<T[]>;
  query?: { lastSyncDate: Date };
  forceSync?: boolean;
}) {
  if ( !forceSync && new Date(query?.lastSyncDate).isSameDay(new Date())) return;

  const paginationService = new PaginationService(type);
  const itemsToUpdate = await repository.dexieTable
    .filter((x) => x.syncState === SyncStateEnum.updatedByUser)
    .toArray();
  const itemsToCreate = await repository.dexieTable
    .filter(
      (x) =>
        x.syncState === SyncStateEnum.userCreated ||
        x.syncState === SyncStateEnum.newInUiTouched
    )
    .toArray();
  const t1 = paginationService.updateItemsInServer(
    endpoint.updateRange,
    itemsToUpdate
  );
  const t2 = fetchAllMethod
    ? await fetchAllMethod()
    : await fetchAll<T[]>(
        endpoint.retrieveAll,
        query.lastSyncDate.toDateOnlyIsoString()
      );
  const t3 = paginationService.createItemsInServer(
    endpoint.createRange,
    itemsToCreate
  );
  const [
    updateItemsResult,
    fetchItemsResult,
    createItemsResult,
  ] = await Promise.all([t1, t2, t3]);

  // Its possible that when we create / update items in server those items will be recognised as no synced from fetch request
  const fetchedItems = fetchItemsResult.filter(
    (x) =>
      !updateItemsResult.some((i) => comparisonFunction(x, i)) &&
      !createItemsResult.some((i) => comparisonFunction(x, i))
  );
  await processFetched(repository, fetchedItems);

  if (updateItemsResult.length > 0)
    await repository.dexieTable.bulkPut(updateItemsResult);
  if (createItemsResult.length > 0) {
    await repository.dexieTable.bulkPut(createItemsResult);
  }
}

async function fetchAll<T>(endpoint: string, lastSyncDate: string): Promise<T> {
  const obj = { lastSyncDate };
  const query = Object.keys(obj)
    .filter((key) => obj[key])
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
  const queryString = new URLSearchParams(query).toString();

  const url = `${endpoint}?${queryString}`;
  const data = await GET<T>(url);
  return data;
}
