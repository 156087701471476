import { SyncGenericWithStream } from "@src/app/points/dataAccess/sync/GenericSync";
import { Offer } from "@src/app/points/windows/info-tabs/offer/models/offer";
import { syncStateService } from "@src/app/State/lastSyncState";
import { OfferRepositoryInstance } from "@src/app/points/dataAccess/offerRepository";
import State from "@src/app/State/appState";
import { OfferEndpoint } from "@src/app/endPoints/OfferEndpoints";
import { DELETE, POST } from "@src/utils/HTTP";
import {SyncStateEnum} from "@src/app/points/dataAccess/sync/ISynchronizable";
import {NoteRepository} from "@src/app/points/dataAccess/noteRepository";
import {Note} from "@src/app/points/windows/info-tabs/notes/models/note";
import {NoteEndpoint} from "@src/app/endPoints/NoteEndpoints";

class SyncOfferService {
  async loadData() {
    const selectedInvestment = State.mapState.value.selectedInvestment.value.id;
    const offers = await OfferRepositoryInstance.search(n => n.investmentId === selectedInvestment);
    State.data.offer.items.next(offers);
  }

  async deleteOffers(ids: number[]) {
    const promises = ids.map((id) => OfferRepositoryInstance.remove(id));
    await Promise.all(promises);
    const query = ids.map((num) => "ids=" + num).join("&");
    await DELETE(`${OfferEndpoint.deleteRange}?${query}`);

    const set = new Set(ids);
    State.data.offer.items.next(
      State.data.offer.items.value.filter((x) => !set.has(x.feId))
    );
  }

  async addNewOffer(offer: Offer) {
    offer.investmentId = State.mapState.value.selectedInvestment.value.id;
    offer.syncState = SyncStateEnum.userCreated;
    OfferRepositoryInstance.add(offer);
    await this.sync(true);
    await this.loadData();
  }

  async sync(isForce = false) {
    const lastSyncDate = syncStateService.state.value.offer?.lastSyncDate;
    await SyncGenericWithStream({
      type: Offer,
      query: { lastSyncDate: new Date(lastSyncDate) },
      repository: OfferRepositoryInstance,
      endpoint: OfferEndpoint,
      forceSync: isForce
    });
    syncStateService.setOfferLastSyncState();
    return true;
  }

  async fetchOffersAsync() {
    const lastSyncDate = syncStateService.state.value.offer?.lastSyncDate;
    await SyncGenericWithStream({
      type: Offer,
      query: { lastSyncDate: new Date(lastSyncDate) },
      repository: OfferRepositoryInstance,
      endpoint: OfferEndpoint,
    });
    State.data.offer.items.next(await OfferRepositoryInstance.getAll());
    syncStateService.setOfferLastSyncState();
  }
}

export const SyncOfferServiceInstance = new SyncOfferService();
