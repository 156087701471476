/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: Investments.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as Investments_pb from './Investments_pb';


export class Investments_GrpcClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAllArea_Grpc = new grpcWeb.MethodDescriptor(
    '/Investments_Grpc/AllArea_Grpc',
    grpcWeb.MethodType.SERVER_STREAMING,
    Investments_pb.investments_request_grpc,
    Investments_pb.investments_reply_grpc,
    (request: Investments_pb.investments_request_grpc) => {
      return request.serializeBinary();
    },
    Investments_pb.investments_reply_grpc.deserializeBinary
  );

  allArea_Grpc(
    request: Investments_pb.investments_request_grpc,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<Investments_pb.investments_reply_grpc> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/Investments_Grpc/AllArea_Grpc',
      request,
      metadata || {},
      this.methodDescriptorAllArea_Grpc);
  }

  methodDescriptorAllExceptArea_Grpc = new grpcWeb.MethodDescriptor(
    '/Investments_Grpc/AllExceptArea_Grpc',
    grpcWeb.MethodType.SERVER_STREAMING,
    Investments_pb.investments_request_grpc,
    Investments_pb.investments_reply_grpc,
    (request: Investments_pb.investments_request_grpc) => {
      return request.serializeBinary();
    },
    Investments_pb.investments_reply_grpc.deserializeBinary
  );

  allExceptArea_Grpc(
    request: Investments_pb.investments_request_grpc,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<Investments_pb.investments_reply_grpc> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/Investments_Grpc/AllExceptArea_Grpc',
      request,
      metadata || {},
      this.methodDescriptorAllExceptArea_Grpc);
  }

}

