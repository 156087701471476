import {
  apiUrl,
  createSyncEndpoints,
  ISynchronizableUrl,
} from "@src/app/endPoints/endPoints";

const Base = `${apiUrl()}/TravelHistory`;

const syncEndpoints = createSyncEndpoints(Base);
export const TravelEndPoints: ISynchronizableUrl = {
  ...syncEndpoints,
};
