import { RIGHT_ARROW } from "@angular/cdk/keycodes";
import { Component, OnInit } from "@angular/core";
import Chart, { ChartDataset } from "chart.js/auto";

@Component({
  selector: "app-doughnut-chart",
  templateUrl: "./doughnut-chart.component.html",
  styleUrls: ["./doughnut-chart.component.scss"],
})
export class DoughnutChartComponent implements OnInit {
  public lineChartData: ChartDataset[] = [];
  constructor() {}

  ngOnInit(): void {}
}
