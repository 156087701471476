import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Note } from "@src/app/points/windows/info-tabs/notes/models/note";

export interface INoteState {
  notes: Note[];
}

const initState: INoteState = {
  notes: [],
};

const slice = createSlice({
  name: "NoteReducer",
  initialState: initState,
  reducers: {
    setNotes: (state, p: PayloadAction<Note[]>) => {
      state.notes = p.payload;
    },
    noteAddedToDexie: (state, p: PayloadAction<Note>) => {
      state.notes = [...state.notes, p.payload];
    },
    deleteNote: (state, p: PayloadAction<{ feId: number }>) => {
      state.notes = state.notes.filter((x) => x.feId !== p.payload.feId);
    },
  },
});

export const noteReducer = slice.reducer;
export const noteActions = slice.actions;
