import Dexie from "dexie";
import {NavibudDbName} from "@src/app/points/dataAccess/repositoryBase";

export const storageService = {
   cleanup: async () => {
    await Dexie.delete(NavibudDbName);
    localStorage.clear();
    sessionStorage.clear();
  }
};
