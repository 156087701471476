import {
  ISynchronizable,
  SyncStateEnum,
} from "@src/app/points/dataAccess/sync/ISynchronizable";

export class SpaceTime implements ISynchronizable {
  lat: number;
  long: number;
  isDeleted: boolean;
  date: string;
  id?: number;
  syncState: SyncStateEnum = SyncStateEnum.userCreated;
  feId: number;

  static ToSpaceTime(position: GeolocationPosition): SpaceTime {
    return {
      // id: null, dexie/indexedDB has to receive no id to autogenerate it
      lat: position.coords.latitude,
      long: position.coords.longitude,
      date: new Date(position.timestamp).toISOString(),
      syncState: SyncStateEnum.userCreated,
      feId: undefined,
      isDeleted: false,
    };
  }
}
