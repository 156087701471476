import {
  ISynchronizable,
  SyncStateEnum,
} from "@src/app/points/dataAccess/sync/ISynchronizable";

export class CalendarEvent implements ISynchronizable {
  id?: number;
  title = "";
  status = CalendarEventStatus.unknown;
  counterparty = "";
  description = "";
  start: string;
  end: string;
  investmentId: number;
  isDeleted: boolean;

  constructor() {
    this.start = new Date().toISOString().slice(0, 16);
    this.end = new Date().toISOString().slice(0, 16);
  }

  syncState: SyncStateEnum;
  feId: number;
}

export enum CalendarEventStatus {
  unknown = "Nieznany",
  open = "Otwarte",
  closed = "Zamkniete",
}
