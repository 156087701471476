import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { LayoutService } from "../../services/layout.service";

@Component({
  selector: "app-main-menu-item",
  templateUrl: "./main-menu-item.component.html",
  styleUrls: ["./main-menu-item.component.css"],
})
export class MainMenuItemComponent {
  @Input() label: string;
  @Input() route?: string;
  @Input() icon: string;

  constructor(private router: Router, private layoutService: LayoutService) {}

  onClick() {
    this.layoutService.closeSidenav();
    if (this.route !== undefined) this.router.navigate([this.route]);
  }
}
