<mat-toolbar class="main-toolbar">
  <mat-toolbar-row>
    <div class="main-toolbar-left">
      <a class="logo" href="/">
        <div class="">
          <img class="logo-image" src="/assets/logo.png" />
        </div>
      </a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div class="page">
  <div class="right-half-screen-cover">
    <div class="form-div">
      <h2>Resetowanie hasła</h2>
      <h3 class="error" [hidden]="!errorMessage">{{ errorMessage }}</h3>
      <form class="form-signin">
        <mat-form-field class="full-width">
          <input
            matInput
            name="email"
            type="email"
            class="form-control"
            placeholder="Adres e-mail"
            [(ngModel)]="email"
            required
            autofocus
          />
        </mat-form-field>
        <div class="auth-buttons">
          <button class="btn-primary" type="submit" (click)="onSubmit()">
            Wyślij link do resetowania hasła
          </button>
          <button (click)="toLogin()">Wróć do strony logowania</button>
        </div>
      </form>
    </div>
  </div>
</div>
