import {Note} from "../windows/info-tabs/notes/models/note";
import Supercluster from "supercluster";
import {ISynchronizable, SyncStateEnum} from "../dataAccess/sync/ISynchronizable";
import {IClustererProperties} from "../containers/map/investment-processor.service";
import {assignWithoutUndefined, Functions, nameOf} from "../services/common/functions";
import {CalendarEvent} from "./event";
import {InvestorTypeEnum, IsFencedEnum} from './enums';
import {GetColor, GetIcon} from "../utils/point-utils";
import {Image} from "./image";
import {names} from "@src/utils/names";

export class MapInvestment implements IMapInvestment, ISynchronizable {
  baseInvestmentId: number;
  syncState: SyncStateEnum;
  userId = ""; // not handled by BE
  id: number;
  fullAddress = "";
  city = "";
  county = "";
  community = "";
  streetName = "";
  postcode = "";
  commune = "";
  district = "";
  plot = "";
  plotNumber = "";
  precinct = "";
  description = "";
  permissionNumber = "";
  intentType = "";
  sellStatus = "unknown";
  buildingStatus = "nieznany";
  permissionDate: string = undefined;
  isVisited = false;
  icon = "";
  seqNum? = "";
  investorFirstName? = "";
  investorLastName? = "";
  investorPhone? = "";
  investorEmail? = "";
  investorCity = "";
  investorPostcode = "";
  investorStreetName = "";
  // note? = ''; BE is still handling that
  notes: Note[] = [];
  lastUpdate: string = undefined;
  imageUrls?: Array<Image>;
  lng: number;
  lat: number;
  identifier = "";
  nextContactDate: string = null;
  investorType = InvestorTypeEnum.undefined;
  importDate: string = undefined;
  nip = "";
  regon = "";
  isFenced = IsFencedEnum.undefined;
  administrationBody = names.unsupportedField;
  sellChances = 1;
  state = "";
  products = "";
  resignationReason = names.unsupportedField;
  streetNumber = "";
  terc = "";
  registrationUnit = "";
  feId: number;
  calendarEvents: CalendarEvent[] = [];
  shallowCopy: MapInvestment;
  overriddenImage: string;
  isDeleted: boolean;
  resignationReaason: string;
  mapNo: string;

  static createMapInvestment(lng: number, lat: number, identifier: string) {
    const newInvestment = new MapInvestment();
    newInvestment.lng = lng;
    newInvestment.lat = lat;
    newInvestment.identifier = identifier;
    return newInvestment;
  }

  static getMarkerImage(mapInvestment: MapInvestment) {
    if (mapInvestment.overriddenImage) return mapInvestment.overriddenImage;
    mapInvestment.buildingStatus ??= "nieznany";
    mapInvestment.sellStatus ??= "unknown";

    let buildingStatus = mapInvestment.buildingStatus.replace(/ /g, "_");
    let sellStatus = mapInvestment.sellStatus.replace(/ /g, "_");

    if (mapInvestment.sellStatus === "Nie podpisano") {
      sellStatus = "nie_podpisano";
    }
    if (mapInvestment.sellStatus === "Podpisano") {
      sellStatus = "podpisano";
    }

    const color = GetColor(sellStatus);

    return GetIcon(color, buildingStatus);
  }

  static toGoogleMapsLatLng(mapInvestment: MapInvestment) {
    return new google.maps.LatLng(mapInvestment.lat, mapInvestment.lng);
  }

  static getFullName(investment: MapInvestment) {
    return `${investment?.investorFirstName || ""} ${investment?.investorLastName || ""
      }`.trim();
  }
  static setDefaultMarkerIcon(marker: google.maps.Marker, investment: MapInvestment) {

    const color = GetColor(investment.sellStatus);
    const iconUrl = GetIcon(color, investment.buildingStatus);


    marker.setIcon({
      url: iconUrl,
      scaledSize: new google.maps.Size(40, 40),
    });
  }

  static toGeoJsonPoint(investment: MapInvestment) {
    return {
      geometry: {
        type: "Point",
        coordinates: [investment.lng, investment.lat],
      },
      properties: {
        investment: investment,
      },
    } as Supercluster.PointFeature<IClustererProperties>; // not assigning two properties - Ram optimization xd
  }

  static new(mapInvestment: IMapInvestment): MapInvestment {
    return (new MapInvestment()).assignObject(mapInvestment);
  }

  assignObject(mapInvestment: IMapInvestment): MapInvestment {
    const defaultDate = "0001-01-01T00:00:00Z";
    const copy: IMapInvestment = {
      ...mapInvestment,
      permissionDate: mapInvestment.permissionDate ?? defaultDate,
      lastUpdate: mapInvestment.lastUpdate ?? defaultDate,
      nextContactDate: mapInvestment.nextContactDate ?? defaultDate,
    };
    return assignWithoutUndefined(this, copy) as MapInvestment;
  }

  hasChanged() {
    if (!this.shallowCopy) return false;
    return !Functions.areEqual(this, this.shallowCopy, [
      nameOf<MapInvestment>("shallowCopy"),
      nameOf<MapInvestment>("feId"),
      nameOf<MapInvestment>("imageUrls"),
      nameOf<MapInvestment>("calendarEvents"),
      nameOf<MapInvestment>("lastUpdate"),
      nameOf<MapInvestment>("seqNum"),
      nameOf<MapInvestment>("notes"),
      nameOf<MapInvestment>("overriddenImage"),
      nameOf<MapInvestment>("importDate"),
      nameOf<MapInvestment>("resignationReaason"),
    ]);
  }

  createShallowCopy() {
    this.shallowCopy = Object.assign(new MapInvestment(), this);
  }
}

export interface IMapInvestment {
  baseInvestmentId: number;
  syncState: SyncStateEnum;
  userId?: string;
  id: number;
  fullAddress: string;
  city: string;
  county: string;
  community: string;
  streetName: string;
  postcode: string;
  commune: string;
  district: string;
  plot: string;
  plotNumber: string;
  precinct: string;
  description: string;
  permissionNumber: string;
  intentType: string;
  sellStatus: string;
  buildingStatus: string;
  permissionDate?: string | null;
  isVisited: boolean;
  icon: string;
  seqNum?: string;
  investorFirstName?: string;
  investorLastName?: string;
  investorPhone?: string;
  investorEmail?: string;
  investorCity: string;
  investorPostcode: string;
  investorStreetName: string;
  notes: Note[];
  lastUpdate?: string | null;
  imageUrls?: Image[];
  lng: number;
  lat: number;
  identifier: string;
  nextContactDate?: string | null;
  investorType: InvestorTypeEnum;
  importDate?: string | null;
  nip: string;
  regon: string;
  isFenced: IsFencedEnum;
  administrationBody: string;
  sellChances: number;
  state: string;
  products: string;
  resignationReason: string;
  streetNumber: string;
  terc: string;
  registrationUnit: string;
  feId: number;
  calendarEvents: CalendarEvent[];
  shallowCopy: MapInvestment;
  overriddenImage: string;
  isDeleted: boolean;
  mapNo: string;
  resignationReaason: string; // Typo corrected: resignationReason
}

