import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUserState {
  position: GeolocationPosition | null;
  isSignal: boolean;
  userId?: string;
}

const initState: IUserState = {
  position: null,
  isSignal: false,
};

const slice = createSlice({
  name: "UserReducer",
  initialState: initState,
  reducers: {
    setPosition: (state, p: PayloadAction<GeolocationPosition>) => {
      state.position = p.payload;
    },
    setSingal: (state, p: PayloadAction<boolean>) => {
      state.isSignal = p.payload;
    },
  },
});

export const userReducer = slice.reducer;
export const userActions = slice.actions;
