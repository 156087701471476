import { createAction, createReducer, on, props } from "@ngrx/store";
import { MapInvestment } from "@src/app/points/models/investment";

export interface IInvestmentState {
  allInvestments: Set<MapInvestment>;
  investmentsToDelete: MapInvestment[];
}

const initState: IInvestmentState = {
  allInvestments: new Set([]),
  investmentsToDelete: [],
};

export const setInvestmentsToDelete = createAction(
  "[InvestmentState] setInvestmentsToDelete",
  props<{ toDelete: MapInvestment[] }>()
);

export const investmentReducer = createReducer(
  initState,
  on(setInvestmentsToDelete, (state, p) => {
    return {
      ...state,
      investmentsToDelete: p.toDelete,
    };
  })
);
