import { SpaceTime } from "../../models/spaceTime";

export const nameOf = <T>(name: Extract<keyof T, string>): string => name;

export class Functions {
  public static getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  static distanceBetweenSpaceTimeAndPostion(
    lastAddedLocation: SpaceTime,
    position: GeolocationPosition
  ) {
    return google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(lastAddedLocation.lat, lastAddedLocation.long),
      new google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
      )
    );
  }

  static toGoogleMapsLatLng(position: GeolocationPosition) {
    if (!position) return null;

    return new google.maps.LatLng(
      position.coords.latitude,
      position.coords.longitude
    );
  }

  static chunk<T>(array: T[], size): Array<Array<T>> {
    const chunked_arr: Array<Array<T>> = [];
    for (let i = 0; i < array.length; i++) {
      const last = chunked_arr[chunked_arr.length - 1];
      if (!last || last.length === size) {
        chunked_arr.push([array[i]]);
      } else {
        last.push(array[i]);
      }
    }
    return chunked_arr;
  }

  static groupBy<Key, T>(items: Array<T>, mapping: (item: T) => Key) {
    const result = new Map<Key, T[]>();
    items.forEach((x) => {
      const key = mapping(x);
      const mapElement = result.get(key);
      if (!mapElement) {
        result.set(key, [x]);
        return;
      }
      mapElement.push(x);
    });
    return result;
  }

  static toQueryString(params: any) {
    return Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
  }

  private static deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  static areEqual<T>(object1: T, object2: T, fieldsToIgnore: string[]) {
    const keys1 = Object.keys(object1).filter(
      (x) => !fieldsToIgnore.includes(x)
    );
    const keys2 = Object.keys(object2).filter(
      (x) => !fieldsToIgnore.includes(x)
    );

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }

    return true;
  }
}

interface IGeoJson {
  geometry: {
    coordinates: Array<number>;
  };
}

export class GeoJson {
  static areEqual(geoJson: IGeoJson, marker: google.maps.Marker): boolean {
    return (
      geoJson.geometry.coordinates[0] === marker.getPosition().lng() &&
      geoJson.geometry.coordinates[1] === marker.getPosition().lat()
    );
  }
}

export class Marker {
  static isEqual(
    marker1: google.maps.Marker,
    marker2: google.maps.Marker
  ): boolean {
    return (
      marker1.getPosition().lng() === marker2.getPosition().lng() &&
      marker1.getPosition().lat() === marker2.getPosition().lat()
    );
  }
}

export function assignWithoutUndefined<T>(target: T, ...sources: T[]) {
  sources.forEach(source => {
    Object.keys(source).forEach(key => {
      if (source[key] !== undefined && source[key] !== null) {
        target[key] = source[key];
      }
    });
  });
  return target;
}

export function defaultIfEmptyOrNull(str: string, defaultValue: string){
  if (!str) {
    return defaultValue;
  }
  return defaultValue;
}
