import EndPoints, {
  createSyncEndpoints,
  ISynchronizableUrl,
} from "@src/app/endPoints/endPoints";

interface IInvestmentEndpoint extends ISynchronizableUrl {
  CounterParties: string;
  CreateSingle: string;
  RetrieveNearest: string;
  RetrieveAllExceptNearest: string;
}

const syncEndpoints = createSyncEndpoints(EndPoints.Investments);

export const InvestmentEndpoints: IInvestmentEndpoint = {
  ...syncEndpoints,
  CounterParties: `${EndPoints.Investments}/counterparties`,
  CreateSingle: `${EndPoints.Investments}/single`,
  RetrieveNearest: `${EndPoints.Investments}/sync/retrieveNearest`,
  RetrieveAllExceptNearest: `${EndPoints.Investments}/sync/retrieveAllExceptNearest`,
};
