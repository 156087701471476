export enum Icon {
  User = "User",
  Unknown = "Nieznany",
  UserDefined = "Stworzony przez uzytkownika",
  PustaDzialka = "Pusta dzialka",
  WytyczonoBudynek = "Wytyczono budynek",
  Wykopy = "Wykopy",
  Fundamenty = "Fundamenty",
  PraceMurarskie = "Prace murarskie",
  ZalanyStrop = "Zalany strop",
  MuryWzniesione = "Mury wzniesione",
  PraceDekarskie = "Prace dekarskie",
  SurowyOtwarty = "Surowy otwarty",
  SurowyZamkniety = "Surowy zamkniety",
  Zamieszkaly = "Zamieszkaly",
  Niezamieszkany = "Niezamieszkany",
}
