import { environment } from "../../environments/environment";

const syncInfo = "SyncInfo";

export function createSyncEndpoints(baseUrl: string): ISynchronizableUrl {
  return {
    createRange: `${baseUrl}/sync/create`,
    retrieveAll: `${baseUrl}/sync/retrieveAll`,
    updateRange: `${baseUrl}/sync/update`,
    deleteRange: `${baseUrl}/sync/delete`,
    page: `${baseUrl}/sync/page`,
  };
}

export function apiUrl(): string {
  const baseUri = environment.api.baseUri;
  return baseUri.endsWith("/") ? baseUri + "api" : baseUri + "/api";
}
export function baseUrl(): string {
  return environment.api.baseUri;
}
export default class EndPoints {
  public static Investments = `${apiUrl()}/investments`;
  public static InvestmentsWithImage = `${EndPoints.Investments}/withImage`;
  public static InvestmentPoints = `${EndPoints.Investments}/points`;
  public static ListAllExcpept = `${EndPoints.Investments}/listAllExcept`;

  public static InvestmentSyncInfo(lastSyncedItemId: number) {
    return `${EndPoints.Investments}/${syncInfo}?LastSyncedItemId=${lastSyncedItemId}`;
  }

  public static UploadImage = (investmentId: number) =>
    `${apiUrl()}/Image/Upload?investmentId=${investmentId}`;
}

export class AuditEndpoint {
  static base = `${apiUrl()}/audit`;
  static changes = (id: string) => `${AuditEndpoint.base}/changes/${id}`;
}

export class ISynchronizableUrl {
  createRange: string;
  retrieveAll: string;
  updateRange: string;
  deleteRange: string;
  page: string;
}
