import { Component, Input, OnInit } from "@angular/core";
import {
  MapInvestment,
} from "@src/app/points/models/investment";
import { InvestorTypeEnum } from "../../models/enums";

@Component({
  selector: "app-investor-info",
  templateUrl: "./investor-info.component.html",
  styleUrls: ["./investor-info.component.css"],
})
export class InvestorInfoComponent implements OnInit {
  @Input() investment: MapInvestment;
  @Input() IsNew = false;
  investorTypes = Object.values(InvestorTypeEnum);
  getFullName: string;

  constructor() {
    this.getFullName = MapInvestment.getFullName(this.investment);
  }

  isCompany = () => InvestorTypeEnum.company === this.investment.investorType;
  isPrivatePerson = () =>
    InvestorTypeEnum.person === this.investment.investorType;

  ngOnInit(): void {}

  call() {
    const url = "tel:" + this.investment.investorPhone;
    const win = window.open(url, "_blank");
    win.location.href = url;
  }

  sendMail() {
    const url = "mailto:" + this.investment.investorEmail;
    const win = window.open(url, "_blank");
    win.location.href = url;
  }
}
