import { EntityServiceBase } from "./EntityServiceBase";
import { PresetRepository } from "../presetRepository";
import { FilterPresetEndpoint } from "../../../endPoints/filterPresetEndpoints";
import { syncStateService } from "../../../State/lastSyncState";
import { FilterPreset } from "../../models/Settings/FilterPreset";

class FilterPresetEntityServiceClass extends EntityServiceBase<FilterPreset> {}
const syncState = syncStateService.state.value;
export const FilterPresetEntityService = new FilterPresetEntityServiceClass(
  PresetRepository,
  FilterPresetEndpoint,
  new Date(syncState.filterPreset?.lastSyncDate),
  FilterPreset,
  syncStateService.setFilterPresetSyncState
);
