import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ["../../../../assets/styles/shared/auth.scss"],
})
export class ConfirmEmailComponent implements OnInit {
  message: string;
  success: boolean = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const token = decodeURIComponent(this.route.snapshot.queryParamMap.get('token') || '');
    const email = this.route.snapshot.queryParamMap.get('email') || '';

    if (token && email) {
      this.authService.confirmEmail(email, token).subscribe({
        next: (response: any) => {
          this.message = response.message;
          this.success = true;
        },
        error: (error) => {
          this.message = error.error.message || `Failed to confirm email`;
          this.success = false;
        }
      });
    }
  }
}
