import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import NoSleep from "nosleep.js";
import "hammerjs";
import "./utils/extensions/date-extensions";

//no sleep ///////////////////
var noSleep = new NoSleep();
document.addEventListener("click", function enableNoSleep() {
  document.removeEventListener("click", enableNoSleep, false);
  setInterval(() => {
    noSleep.enable();
    noSleep.disable();
  }, 30000);
});
/////////////////////////////

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
