import { CalendarEvent } from "../points/models/event";
import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { CalendarRepository } from "../points/dataAccess/calendar-repository.service";

@Injectable({
  providedIn: "root",
})
export class CalendarStore {
  state = new BehaviorSubject<CalendarEvent[]>([]);

  constructor() {}

  loadAllEvents() {
    CalendarRepository.getAll().then((x) => this.state.next(x));
  }

  loadInvestmentEvents(investmentId: number) {
    CalendarRepository.getAll().then((x) => {
      const newState = x.filter((i) => i.investmentId === investmentId);
      this.state.next(newState);
    });
  }
}
