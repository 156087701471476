import { User } from "../../core/models/user";
import { Injectable } from "@angular/core";

@Injectable()
export class UserService {
  get user(): User {
    const userData = localStorage.getItem("user");
    const login = userData.split(":")[0];
    return new User(login, "Administrator");
  }
}
