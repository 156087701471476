import { Injectable } from "@angular/core";
import { filter, take } from "rxjs/operators";
import { GeolocationRepository } from "../geolocationRepository";
import { CalendarEvent } from "../../models/event";
import { CalendarRepository } from "../calendar-repository.service";
import { PlanedRouteRepositoryInstance } from "@src/app/points/dataAccess/plannedRouteRepository";
import { Store } from "@ngrx/store";
import { INaviStore } from "@src/app/State/RootReducer";
import { InvestmentRepository } from "@src/app/points/dataAccess/investmentRepository";
import {
  actualSyncVersion,
  syncStateService,
} from "@src/app/State/lastSyncState";
import { CalendarEventsEndpoint } from "@src/app/endPoints/CalendarEndpoints";
import { SyncGenericWithStream } from "@src/app/points/dataAccess/sync/GenericSync";
import { NoteServiceInstance } from "@src/app/points/windows/info-tabs/notes/effect-notes";
import { setCalendarEvents } from "@src/app/auth/redux/calendarState";
import { busyIndicatorService } from "@src/app/auth/redux/busyIndicatorState";
import { SpaceTime } from "@src/app/points/models/spaceTime";
import { TravelEndPoints } from "@src/app/endPoints/travelEndpoints";
import { SyncInvestmentServiceInstance } from "@src/app/points/dataAccess/sync/sync-investment.service";
import State from "@src/app/State/appState";
import { SyncOfferServiceInstance } from "@src/app/points/dataAccess/sync/sync-offer.service";
import { isMobile } from "@src/utils/utils";
import { FilterPresetEntityService } from "@src/app/points/dataAccess/sync/PresetEntityService";
import { FilterPreset } from "@src/app/points/models/Settings/FilterPreset";
import {Log} from "@src/utils/log";
import {MapInvestment} from "@src/app/points/models/investment";

interface ISyncState {
  date: Date;
}

export interface ISyncGlobalState {
  investments: ISyncState;
}

@Injectable({
  providedIn: "root",
})
export class SyncService {
  constructor(private store: Store<INaviStore>) {}
  isSyncing = false;

  async sync() {
    if (this.isSyncing) return;
    this.isSyncing = true;

    busyIndicatorService.setBusy("starting global synchronization");
    const lastSyncInfo = syncStateService.state.value;
    if (!lastSyncInfo.version || lastSyncInfo.version < actualSyncVersion) {
      syncStateService.resetSyncState();
      await GeolocationRepository.dexieTable.clear();
      await CalendarRepository.dexieTable.clear();
      await PlanedRouteRepositoryInstance.dexieTable.clear();
      await InvestmentRepository.dexieTable.clear();
    }

    FilterPresetEntityService.SyncGenericWithStream().then(async () => {
      await FilterPresetEntityService.loadAll();
      if (!FilterPresetEntityService.itemsValue().length) {
        await FilterPresetEntityService.add(FilterPreset.createNew());
      }

      const selectedPreset =
        FilterPresetEntityService.itemsValue().find(
          (x) => x.jsonData?.isDefault
        ) ?? FilterPresetEntityService.itemsValue()[0];
      State.mapState.value.selectedFilter.next(selectedPreset);
    }); // this service should be used thru all entities !!!!
    SyncOfferServiceInstance.sync();
    NoteServiceInstance.sync();
    this.SyncTravelsAsync();
    this.syncCalendarAsync().then(() => console.log("event synced"));

    State.user
      .pipe(
        filter((x) => !!x.position),
        take(1)
      )
      .subscribe(async () => {
        if (isMobile()) {
          await SyncInvestmentServiceInstance.SyncNearestAsync();
        } else {
          try {
            await Promise.all([SyncInvestmentServiceInstance.SyncNearestAsync(),
              SyncInvestmentServiceInstance.SyncAllExceptNearest()]);
            Log.debug("SyncAllExceptNearest synced");
          } catch (e) {
            Log.Error("SyncAllExceptNearest investments fail", e);
          }
        }
        const allInvestments = await InvestmentRepository.getAll();
        Log.debug(`SyncAllExceptNearest Loaded ${allInvestments.length} investments from db`);
        State.data.investment.items.next(new Set<MapInvestment>(allInvestments));

        busyIndicatorService.setFree("position has been set, global sync end");
        this.isSyncing = false;
        syncStateService.setInvestmentLastSyncState();
      });
  }

  async syncCalendarAsync(isForce = false) {
    const currentSyncDate = new Date();
    const lastSyncDate = syncStateService.state.value.calendar.lastSyncDate;
    await SyncGenericWithStream({
      type: CalendarEvent,
      repository: CalendarRepository,
      endpoint: CalendarEventsEndpoint,
      query: { lastSyncDate: new Date(lastSyncDate) },
      forceSync: isForce
    });
    const calendarEvents = await CalendarRepository.getAll();
    this.store.dispatch(setCalendarEvents(calendarEvents));
    syncStateService.setCalendarLastSyncState(currentSyncDate);
  }

  private async SyncTravelsAsync() {
    const lastSyncDate = syncStateService.state.value.travels.lastSyncDate;
    await SyncGenericWithStream({
      type: SpaceTime,
      query: { lastSyncDate: new Date(lastSyncDate) },
      repository: GeolocationRepository,
      endpoint: TravelEndPoints,
    });
    syncStateService.setTravelsLastSyncState();
  }
}
