import { Component, OnInit } from "@angular/core";
import { UserPasswordComponent } from "../user-password/user-password.component";
import { MatDialog } from "@angular/material/dialog";
import { UserService } from "@src/app/auth/services/user.service";

@Component({
  selector: "app-user-data",
  templateUrl: "./user-data.component.html",
  styleUrls: ["./user-data.component.scss"],
})
export class UserDataComponent implements OnInit {
  constructor(private userService: UserService, public dialog: MatDialog) {}
  ngOnInit(): void {}
  openPasswordDialog() {
    const dialogRef = this.dialog.open(UserPasswordComponent, {
      data: this.getUserData(),
      panelClass: "custom-mat-panel",
    });
  }

  getUserData() {}

  get login(): string {
    return this.userService.user.login;
  }

  get role(): string {
    return this.userService.user.role;
  }
}
