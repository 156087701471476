import { Injectable } from "@angular/core";
import { IMapPoint } from "../../core/models/map-point";
import { GeolocationRepository } from "../dataAccess/geolocationRepository";
import { SpaceTime } from "../models/spaceTime";
import { Functions } from "./common/functions";
import { Store } from "@ngrx/store";
import { INaviStore } from "@src/app/State/RootReducer";
import { userActions } from "@src/app/State/user-slice";
import { take } from "rxjs/operators";
import State from "@src/app/State/appState";

@Injectable({
  providedIn: "root",
})
export class UserLocationService {
  constructor(private store: Store<INaviStore>) {}
  // I'm dummy - responsible for tracking new location ONLY
  public accuracy: number;
  public isSignal = false;
  public lastPosition: IMapPoint;
  private isTrackingLocation: boolean;

  static positionErrorCallback(error: any) {
    console.log("Error - cannot get location: ", error);
  }

  static isChrome() {
    const isSafari: boolean = /^((?!chrome|android).)*safari/i.test(
      navigator.userAgent
    );
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    return !isSafari && !isIEOrEdge;
  }

  gotGeolocationPermission(): boolean {
    if (!navigator.geolocation) return false;

    if (UserLocationService.isChrome() && this.gotChromePermissions()) {
      return true;
    } else {
      if (navigator.geolocation) {
        return true;
      } else {
        alert("Geolocation is not available");
        return false;
      }
    }
  }

  startWatching() {
    if (this.isTrackingLocation) return;
    this.isTrackingLocation = true;
    navigator.geolocation.watchPosition(
      async (position) => {
        this.store.dispatch(userActions.setSingal(true));
        const hasAddedNewLocation = await this.addToGeolocationRepository(
          position
        );
        const currentUserPosition = await this.store
          .select((x) => x.user.position)
          .pipe(take(1))
          .toPromise();
        if (hasAddedNewLocation || !currentUserPosition) {
          State.user.next({
            isSignal: true,
            position,
          });

          this.store.dispatch(userActions.setPosition(position));
        }
      },
      (error: any) => {
        this.store.dispatch(userActions.setSingal(false));
        UserLocationService.positionErrorCallback(error);
      },
      { enableHighAccuracy: true, timeout: 5000 }
    );
  }

  private gotChromePermissions(): boolean {
    const permissions: any = navigator["permissions"];
    // user will be queried (alarm) if can get permissions
    return permissions.query({ name: "geolocation" }).then((result: any) => {
      if (result.state === "granted") {
        return true;
      } else if (result.state === "prompt") {
        navigator.geolocation.getCurrentPosition(
          () => {
            return true;
          },
          () => {
            return false;
          }
        );
      } else if (result.state === "denied") {
        return false;
      }
    });
  }

  private async addToGeolocationRepository(
    position: GeolocationPosition
  ): Promise<boolean> {
    const lastAddedLocation = await GeolocationRepository.getLast();
    if (!lastAddedLocation) {
      const positionToAdd = SpaceTime.ToSpaceTime(position);
      delete positionToAdd.feId;
      await GeolocationRepository.dexieTable.add(positionToAdd);
      return true;
    }

    const distance = Functions.distanceBetweenSpaceTimeAndPostion(
      lastAddedLocation,
      position
    );
    this.accuracy = Math.round(position.coords.accuracy);

    if (distance > this.accuracy && Math.abs(distance - this.accuracy) > 15) {
      // in meters
      const st = SpaceTime.ToSpaceTime(position);
      delete st.feId;
      await GeolocationRepository.add(st);
      return true;
    }
    return false;
  }
}
