import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { from, of } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";
import { createAction, props, Store } from "@ngrx/store";
import { InvestmentsService } from "@src/app/points/services/investmentsDomain.service";
import { MapInvestment } from "@src/app/points/models/investment";

export const deleteInvestmentEffect = createAction(
  "[Effect] delete investment",
  props<{ investments: MapInvestment[] }>()
);
export const deleteInvestmentEffectSuccess = createAction(
  "[Effect] delete investment success"
);

@Injectable()
export class InvestmentEffects {
  deleteInvestment = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteInvestmentEffect),
        switchMap((action) => {
          return from(this.investmentsService.delete(action.investments)).pipe(
            map(() => deleteInvestmentEffectSuccess()),
            catchError(() =>
              of(() => console.log("unable to sync planned routes"))
            )
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private investmentsService: InvestmentsService
  ) {}
}
