import { BehaviorSubject } from "rxjs";

interface ILastSync {
  lastSyncDate: string;
}

export const actualSyncVersion = 9;
export interface ILastSyncInfo {
  investments: ILastSync;
  travels: ILastSync;
  calendar: ILastSync;
  offer: ILastSync;
  note: ILastSync;
  filterPreset: ILastSync;
  version: number;
}
const lastSyncInfoKey = "lastSyncInfo";

const syncInfoJson = localStorage.getItem(lastSyncInfoKey);
const lastSyncState = JSON.parse(syncInfoJson) as ILastSyncInfo;
const state = new BehaviorSubject(lastSyncState);
const defaultState: ILastSyncInfo = {
  note: {
    lastSyncDate: null,
  },
  filterPreset: {
    lastSyncDate: null,
  },
  investments: {
    lastSyncDate: null,
  },
  travels: {
    lastSyncDate: null,
  },
  calendar: {
    lastSyncDate: null,
  },
  offer: {
    lastSyncDate: null,
  },
  version: actualSyncVersion,
};

if (
  !(
    lastSyncState?.travels &&
    lastSyncState?.investments &&
    lastSyncState?.calendar
  )
) {
  state.next(defaultState);
  localStorage.setItem(lastSyncInfoKey, JSON.stringify(lastSyncState));
}

const setState = (values: Partial<ILastSyncInfo>) => {
  const nextState = {
    ...state.value,
    ...values,
  };
  state.next(nextState);
  return nextState;
};
export const syncStateService = {
  state,
  resetSyncState: () => {
    const newState = defaultState;
    localStorage.setItem(lastSyncInfoKey, JSON.stringify(defaultState));
    setState(newState);
  },
  setInvestmentLastSyncState: (syncTime: Date = new Date()) => {
    const newState = setState({
      investments: {
        lastSyncDate: syncTime.toISOString(),
      },
    });
    localStorage.setItem(lastSyncInfoKey, JSON.stringify(newState));
  },
  setFilterPresetSyncState: (syncTime: Date = new Date()) => {
    const newState = setState({
      filterPreset: {
        lastSyncDate: syncTime.toISOString(),
      },
    });
    localStorage.setItem(lastSyncInfoKey, JSON.stringify(newState));
  },
  setTravelsLastSyncState: (syncTime: Date = new Date()) => {
    const newState = setState({
      travels: {
        lastSyncDate: syncTime.toISOString(),
      },
    });
    localStorage.setItem(lastSyncInfoKey, JSON.stringify(newState));
  },
  setCalendarLastSyncState: (syncTime: Date = new Date()) => {
    const newState = setState({
      calendar: {
        lastSyncDate: syncTime.toISOString(),
      },
    });
    localStorage.setItem(lastSyncInfoKey, JSON.stringify(newState));
  },
  setOfferLastSyncState: (syncTime: Date = new Date()) => {
    const newState = setState({
      offer: {
        lastSyncDate: syncTime.toISOString(),
      },
    });
    localStorage.setItem(lastSyncInfoKey, JSON.stringify(newState));
  },
  setNoteLastSyncState: (syncTime: Date = new Date()) => {
    const newState = setState({
      note: {
        lastSyncDate: syncTime.toISOString(),
      },
    });
    localStorage.setItem(lastSyncInfoKey, JSON.stringify(newState));
  },
};
