import { SellStatusEnum } from '../models/enums';

export function GetColor(sellStatus: string): string {
    sellStatus = sellStatus.toLowerCase();
    switch (sellStatus) {
        case SellStatusEnum.None:
        case SellStatusEnum.Unknown:
            return "#c65245ff"; // czerwony
        case SellStatusEnum.Negocjaje:
            return "#38a3a8ff"; // turkus
        case SellStatusEnum.OfertaWyslana:
            return "#377f60ff"; // zielony
        case SellStatusEnum.RozmowaTelefoniczna:
            return "#f6d046ff"; // zolty
        case SellStatusEnum.RozmowaZklientem:
            return "#f6d046ff"; // zolty
        case SellStatusEnum.Ulotka:
            return "#e9994dff"; // pomaranczowy
        case SellStatusEnum.Pomiar:
            return "#a09447ff"; // oliwkowy
        case SellStatusEnum.Podpisano:
            return "#377f60ff"; // zielony
        case SellStatusEnum.NiePodpisano:
            return "#FF0000"; // czerwony
        default:
            return "#ccc"; // szary dla nieznanych lub niesprecyzowanych statusów
    }
}

export function GetIcon(color: string, buildingStatus: string): string {
    let pathD = "";
    let gTransform = "";
    const bgColor = "#FFFFFF";
    switch (buildingStatus) {
        case "wykopy":
            gTransform = "matrix(.10014 0 0 .10014 -.06 -.107)"
            pathD = "M129.506 17.31c-44.18 0-80.003 33.76-80.003 75.4v414.7c0 41.64 35.823 75.4 80.003 75.4h163.789c7.57 0 17.214-.007 32.024 32.083l24.184 52.405 24.185-52.405c14.81-32.09 24.454-32.083 32.024-32.083h163.79c44.179 0 80.002-33.76 80.002-75.4V92.71c0-41.64-35.823-75.4-80.003-75.4zm350.221 74.698 51.567 44.897-13.223 13.555-52.874-47.062zm-3.783 35.184 14.861 13.399L305.25 346.527l33.449 31.615c-2.47 2.073-10.252 8.422-11.546 9.459-15.215 12.207-31.238 23.569-49.168 31.361-22.077 9.949-48.053 12.08-70.798 3.023-2.605-14.288-2.089-29.205 2.029-43.16 7.535-25.228 24.056-46.121 41.64-65.356l8.893-9.577c.063-.066.132-.128.195-.195l30.776 29.1zM124.513 479.306h450v14.003h-450Z";
            break;
        case "fundamenty":
            gTransform = "scale(.10014)"
            pathD = "M129.502 18.411c-44.18 0-80.003 33.76-80.003 75.4v414.7c0 41.64 35.823 75.4 80.003 75.4h163.79c7.57 0 17.214-.007 32.024 32.083l24.184 52.425 24.184-52.425c14.81-32.09 24.455-32.083 32.025-32.083h163.789c44.18 0 80.003-33.76 80.003-75.4V93.81c0-41.64-35.823-75.4-80.003-75.4zm411.053 391.92.487 46.574H159.83l.078-45.677zm33.955 67.716.605 22.487H125.114l.097-22.058z";
            break;
        case "mury_wzniesione":
            pathD = "M13.48 1.506c-4.424 0-8.01 3.38-8.01 7.55v41.53c0 4.17 3.586 7.55 8.01 7.55h16.403c.758 0 1.724 0 3.207 3.214l2.424 5.248 2.422-5.248c1.483-3.214 2.448-3.213 3.207-3.213h16.402c4.424 0 8.01-3.381 8.01-7.551V9.056c0-4.17-3.586-7.55-8.01-7.55zm4.006 24.85H53.54v17.023H30.5V30.965h-8.41v12.414h-4.604zm20.315 4.296v7.317h10.78v-7.317zm19.826 17.13.059 2.251H12.62l.01-2.209z";
            break;
        case "nie_podpisano":
            gTransform = "";
            pathD = "M50 50 L60 60";
            break;
        case "nieznany":
        case "Nieznany":
            gTransform = ""
            pathD = "M12.957031 1.929688c-4.417993 0-8 3.377018-8 7.541015v41.46875c0 4.163998 3.582007 7.541016 8 7.541016h16.378907c.756996 0 1.72017-.000018 3.201171 3.208984l2.419922 5.240235 2.419922-5.240235c1.480993-3.209002 2.444166-3.208984 3.201172-3.208984h16.378906c4.417994 0 8-3.377018 8-7.541016V9.470703c0-4.163997-3.582006-7.541015-8-7.541015zm21.753907 10.117187c2.555555 0 4.604818.645834 6.146484 1.9375 1.555555 1.291667 2.332031 3.054905 2.332031 5.291016 0 1.069444-.229167 2.160373-.6875 3.271484-.458333 1.097222-1.651476 2.561198-3.582031 4.394531-1.458333 1.375-2.437501 2.452692-2.9375 3.230469-.5.777778-.75 1.616754-.75 2.519531 0 1.013889.242405 2.070747.728516 3.167969h-3.896485c-.347222-1.027778-.519531-2.126302-.519531-3.292969 0-.916666.208333-1.838976.625-2.769531.416666-.944444 1.479167-2.243707 3.1875-3.896484 1.749999-1.652778 2.818142-2.895183 3.207031-3.728516.402778-.833333.605469-1.625001.605469-2.375 0-1.333333-.473524-2.382596-1.417969-3.146484-.944444-.777778-2.145182-1.166016-3.603515-1.166016-2.541667 0-4.763239 1-6.666016 3v-4.271484c2.319444-1.444444 4.728517-2.166016 7.228516-2.166016zm-.478516 27.978516c.736111 0 1.366754.263237 1.894531.791015.527778.513888.792969 1.133246.792969 1.855469 0 .694444-.265191 1.304254-.792969 1.832031-.513889.513889-1.11046.771485-1.791015.771485-.833334 0-1.507596-.263239-2.021485-.791016-.513889-.527777-.769531-1.131945-.769531-1.8125 0-.722223.263237-1.341581.791016-1.855469.527777-.527778 1.160373-.791015 1.896484-.791015z";
            break;
        case "prace_dekarskie":
            gTransform = "";
            pathD = "M12.968719 1.844C8.5445567 1.844 4.957 5.2249724 4.957 9.3947812V50.922125c0 4.16981 3.5875567 7.550782 8.011719 7.550782h16.402343c.75806 0 1.722013-.000601 3.205079 3.21289l2.421875 5.248046 2.421875-5.248046c1.483063-3.213477 2.448971-3.21289 3.207031-3.21289h16.402343c4.424163 0 8.011719-3.380972 8.011719-7.550782V9.3947812c0-4.1698088-3.587556-7.5507812-8.011719-7.5507812Zm22.074219 5.78125 24.59375 15.142578H10.453094Zm-.607423 2.0078125-4.560546 2.9003905v8.621094h4.560546zm1.437501.042969V21.154547h4.25v-8.77539zm5.687499 3.6171865v7.861329h3.593751v-5.576172zm-13.123046.154297-4.3125 2.742188v4.964844h4.3125zm18.154296 3.044922v4.66211h5.000001v-1.482422zm-23.904296.611329-4.28125 2.722656v1.328125h4.28125zm30.341796 3.482421v.56836h.892579zm-36.060546.154297-.652344.414063h.652344zm-2.257813 3.742188h40.822266v19.232422H30.373016V31.301031h-8.410157v12.414063h-7.251953zm24.253907 6.248047v7.316406h10.779296v-7.316406ZM12.511688 48.19361h45.050781v1.43164H12.511688Z";
            break;
        case "prace_murarskie":
            gTransform = "";
            pathD = "M13.011718 1.844C8.587412 1.844 5 5.224837 5 9.394781v41.529297c0 4.169945 3.587412 7.550781 8.011718 7.550781h16.402344c.758083 0 1.723919-.000701 3.207031 3.212891l2.421876 5.248047 2.421875-5.248047c1.483113-3.213582 2.448948-3.212891 3.207031-3.212891h16.402344c4.424305 0 8.011718-3.380836 8.011718-7.550781V9.394781c0-4.169944-3.587413-7.550781-8.011718-7.550781Zm4.00586 28.212891h21.605469v7.990234h10.779296v-7.990234h3.666017v13.660156H30.029297l-.0039-13.664063-8.421875-.03906.01563 13.703126h-4.601574zm-4.47461 18.148437h45.066407v1.398438H12.542969Z";
            break;
        case "pusta_dzialka":
            gTransform = "scale(.10014)";
            pathD = "M129.502 10.376c-44.18 0-80.003 33.76-80.003 75.4v414.7c0 41.64 35.823 75.4 80.003 75.4h163.79c7.57 0 17.214.012 32.024 32.102l24.184 52.405 24.184-52.405c14.81-32.09 24.455-32.103 32.025-32.103h163.789c44.18 0 80.003-33.76 80.003-75.4v-414.7c0-41.64-35.823-75.4-80.003-75.4zm444.404 406.333.585 22.487H124.509l.078-22.058z";
            break;
        case "stworzony_przez_uzytkownika":
            gTransform = "";
            pathD = "M12.968719 1.844C8.5445567 1.844 4.957 5.2249724 4.957 9.3947812V50.922125c0 4.16981 3.5875567 7.550782 8.011719 7.550782h16.402343c.75806 0 1.722013-.000601 3.205079 3.21289l2.421875 5.248046 2.421875-5.248046c1.483063-3.213477 2.448971-3.21289 3.207031-3.21289h16.402343c4.424163 0 8.011719-3.380972 8.011719-7.550782V9.3947812c0-4.1698088-3.587556-7.5507812-8.011719-7.5507812Zm22.074219 5.78125 24.59375 15.142578H10.453094Zm-.607423 2.0078125-4.560546 2.9003905v8.621094h4.560546zm1.437501.042969V21.154547h4.25v-8.77539zm5.687499 3.6171865v7.861329h3.593751v-5.576172zm-13.123046.154297-4.3125 2.742188v4.964844h4.3125zm18.154296 3.044922v4.66211h5.000001v-1.482422zm-23.904296.611329-4.28125 2.722656v1.328125h4.28125zm30.341796 3.482421v.56836h.892579zm-36.060546.154297-.652344.414063h.652344zm-2.257813 3.742188h40.822266v19.232422H30.373016V31.301031h-8.410157v12.414063h-7.251953zm24.253907 6.248047v7.316406h10.779296v-7.316406ZM12.511688 48.19361h45.050781v1.43164H12.511688Z";
            break;
        case "surowy_otwarty":
            gTransform = "";
            pathD = "M13.01147378 1.85C8.58731161 1.85 5 5.23074152 5 9.4005506v41.52802745c0 4.16980957 3.58731161 7.550551 8.01147378 7.550551h16.40184248c.7580548 0 1.72387004-.00070098 3.20693343 3.21279262l2.42180078 5.24788677 2.42375451-5.24788677c1.48306339-3.21347758 2.4469249-3.21279262 3.2049797-3.21279262h16.40184248c4.42416517 0 8.01147438-3.38074143 8.01147438-7.550551V9.4005506c0-4.16980908-3.58730921-7.5505506-8.01147438-7.5505506zM34.639719 8.85955146l21.02865496 13.01718087H13.61106503Zm-17.62250802 17.8392984h36.04968v17.02291776H30.03048509V31.30808376h-8.4098994v12.41368386h-4.60337472zm21.60480845 4.0389396v7.31618234h10.77896746v-7.31618233ZM12.87671238 48.22553609H58.0569723v1.42573725H12.87671238Z";
            break;
        case "surowy_zamkniety":
            gTransform = "matrix(.10014 0 0 .10014 -2e-7 .07599445)";
            pathD = "M129.50224 18.510449c-44.17964 0-80.002739 33.760315-80.002739 75.399946V508.61009c0 41.63964 35.823099 75.39995 80.002739 75.39995h163.78912c7.56991 0 17.21459-.007 32.0245 32.08301l24.18415 52.425 24.18415-52.425c14.80982-32.08973 24.45459-32.08301 32.0245-32.08301h163.78912c44.17955 0 80.00273-33.76031 80.00273-75.39995V93.910395c0-41.639631-35.82318-75.399946-80.00273-75.399946zM346.08692 148.81232l209.99255 130.00932h-419.9851zm103.93335 5.10988h53.10761V225.53849l-53.10761-32.88264ZM169.5036 296.39416h359.99281v170.01069H169.5036Zm198.17353 13.69135v100.40324h141.41878V310.08551Zm9.18608 6.55312h123.88526v87.9211H376.86321Zm-169.30857 6.63114V464.3765h7.89885V331.18813h83.98142V464.3765h5.77299V323.26977Zm177.69501 1.30673v73.05954h49.42148V324.5765Zm58.76358 0v73.05954h48.8754V324.5765Zm-158.26967 19.44484v102.78264h4.60279V344.02134Zm-61.66959.11702V446.921h4.60279V344.13836Zm26.89512 6.57262v95.68343h12.98923v-95.68343zm323.15098 133.30539.5851 19.97143H125.34802l.0975-19.60086z";
            break;
        case "mury_wzniesione":
            gTransform = "";
            pathD = "M13.48 1.506c-4.424 0-8.01 3.38-8.01 7.55v41.53c0 4.17 3.586 7.55 8.01 7.55h16.403c.758 0 1.724 0 3.207 3.214l2.424 5.248 2.422-5.248c1.483-3.214 2.448-3.213 3.207-3.213h16.402c4.424 0 8.01-3.381 8.01-7.551V9.056c0-4.17-3.586-7.55-8.01-7.55zm4.006 24.85H53.54v17.023H30.5V30.965h-8.41v12.414h-4.604zm20.315 4.296v7.317h10.78v-7.317zm19.826 17.13.059 2.251H12.62l.01-2.209z";
            break;
        case "wytyczono_budynek":
            pathD = "M12.969 1.598c-4.425 0-8.012 3.38-8.012 7.55V50.68c0 4.17 3.587 7.55 8.012 7.55H29.37c.758 0 1.724 0 3.207 3.213L35 66.691l2.422-5.248c1.483-3.213 2.449-3.213 3.207-3.213v-.002H57.03c4.425 0 8.012-3.38 8.012-7.55V9.148c0-4.17-3.587-7.55-8.012-7.55zm34.338 25.287v1.822h6.357c.03 0 .055.033.055.074v4.403c0 .04-.025.072-.055.072h-6.357v13.896l-2.21-.004-.029-13.892H24.834v13.902l-2.209-.004-.03-13.898h-6.503c-.03 0-.055-.032-.055-.072V28.78c0-.04.025-.074.055-.074h6.494l-.004-1.789 2.252-.027v1.816h20.225l-.004-1.795zm10.107 20.818.059 2.252H12.408l.01-2.209z";
            break;
        case "zalany_strop":
            gTransform = "";
            pathD = "M13.01147348 1.85C8.58731232 1.85 5 5.23074152 5 9.4005506v41.52802745c0 4.16980957 3.58731232 7.550551 8.01147348 7.550551h16.40184248c.7580598 0 1.72387004-.00070098 3.20693343 3.21279262l2.42180178 5.24788677 2.42180078-5.24788677c1.48306339-3.21347758 2.44887363-3.21279262 3.20693343-3.21279262h16.40184248c4.42416016 0 8.01147338-3.38074143 8.01147338-7.550551V9.4005506c0-4.16980908-3.58731322-7.5505506-8.01147338-7.5505506zm-.51560884 18.9994191h45.05135772v1.84369357H12.49586464Zm2.97061204 3.86902306h39.32301726v19.00332546H30.02853206V31.30808376h-8.4098994v12.41368386h-6.15215598zm23.15554244 6.0193473v7.31618234h10.77896746v-7.31618233ZM12.5446909 47.93257652h45.05135772v1.64252733H12.5446909Z";
            break;
        case "zamieszkaly":
            gTransform = "matrix(.10014 0 0 .10014 0 .075994)";
            pathD = "M129.50224 18.510449c-44.17964 0-80.002739 33.760315-80.002739 75.399946V508.61009c0 41.63964 35.823099 75.39995 80.002739 75.39995h163.78912c7.56991 0 17.21459-.007 32.0245 32.08301l24.18415 52.425 24.18415-52.425c14.80982-32.08973 24.45459-32.08301 32.0245-32.08301h163.78912c44.17955 0 80.00273-33.76031 80.00273-75.39995V93.910395c0-41.639631-35.82318-75.399946-80.00273-75.399946zM346.08692 148.81232l209.99255 130.00932h-419.9851zm103.93335 5.10988h53.10761V225.53849l-53.10761-32.88264ZM169.5036 296.39416h359.99281v170.01069H169.5036Zm198.17353 13.69135v100.40324h141.41878V310.08551Zm9.18608 6.55312h123.88526v87.9211H376.86321Zm-169.30857 6.63114V464.3765h7.89885V331.18813h83.98142V464.3765h5.77299V323.26977Zm177.69501 1.30673v73.05954h49.42148V324.5765Zm58.76358 0v73.05954h48.8754V324.5765Zm-158.26967 19.44484v102.78264h4.60279V344.02134Zm-61.66959.11702V446.921h4.60279V344.13836Zm26.89512 6.57262v95.68343h12.98923v-95.68343zm323.15098 133.30539.5851 19.97143H125.34802l.0975-19.60086z";
            break;
        default:
            gTransform = "matrix(.10014 0 0 .10014 -.06 -.107)"
            pathD = "M129.506 17.31c-44.18 0-80.003 33.76-80.003 75.4v414.7c0 41.64 35.823 75.4 80.003 75.4h163.789c7.57 0 17.214-.007 32.024 32.083l24.184 52.405 24.185-52.405c14.81-32.09 24.454-32.083 32.024-32.083h163.79c44.179 0 80.002-33.76 80.002-75.4V92.71c0-41.64-35.823-75.4-80.003-75.4zm350.221 74.698 51.567 44.897-13.223 13.555-52.874-47.062zm-3.783 35.184 14.861 13.399L305.25 346.527l33.449 31.615c-2.47 2.073-10.252 8.422-11.546 9.459-15.215 12.207-31.238 23.569-49.168 31.361-22.077 9.949-48.053 12.08-70.798 3.023-2.605-14.288-2.089-29.205 2.029-43.16 7.535-25.228 24.056-46.121 41.64-65.356l8.893-9.577c.063-.066.132-.128.195-.195l30.776 29.1zM124.513 479.306h450v14.003h-450Z";
    }

    const svg = `<svg width="70" height="70" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg">
                  <rect
                    style="fill:${bgColor};fill-opacity:1;stroke-width:1.73346"
                    width="48"
                    height="48"
                    x="10.762608"
                    y="4.9937983"
                  />
                  <g transform="${gTransform}">
                    <path fill="${color}" d="${pathD}"/>
                    </g> 
                </svg>`;

    return `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`;
}