export enum IsFencedEnum {
    undefined = "Nieznany",
    yes = "Tak",
    no = "Nie",
}

export enum InvestorTypeEnum {
    undefined = "Nieznany",
    person = "Osoba prywatna",
    company = "Firma",
}

export enum SellStatusEnum {
    None = "nieokreslony",
    Unknown = "unknown",
    OfertaWyslana = "oferta_wyslana",
    Podpisano = "podpisano",
    RozmowaTelefoniczna = "rozmowa_telefoniczna",
    RozmowaZklientem = "rozmowa_z_klientem",
    Ulotka = "ulotka",
    Pomiar = "pomiar",
    NiePodpisano = "nie_podpisano",
    Negocjaje = "negocjacje",

}

export enum ImageTypeEnum {
    Normal = 0,
    Miniature = 1,
}
